import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12} md={4}>
      <Typography gutterBottom>
        The mitral valve prevents backflow of blood from the left ventricle (the chamber that pumps blood through the body) to the left atrium (the chamber that pumps blood to the left ventricle).  This valve often gets a bit leaky with age, and the leak is heard as a heart murmur.
      </Typography>
      </Grid>
      <Grid item xs={12} md={4}>

      <Typography>
        This is really common in older dogs, especially small breeds, but most of these dogs never develop clinical signs associated with their leaky valves.
      </Typography>
      </Grid>
      <Grid item xs={12} md={4}>

      <Typography>
        The leak causes a decrease in function, and the heart often makes structural changes (with some chambers getting bigger) to compensate for this loss of function.  There are medications (including Pimobendan) that will help dogs live longer if the structural changes are great enough.  Ideally, this is evaluated by echocardiography.  In the absence of an echo, thoracic radiographs for vertebral heart scoring can help determine if treatment with Pimobendan would benefit the dog.<sup>1,2</sup>
      </Typography>
      </Grid>
      <Grid item xs={12}>

      <Typography>
      <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952593" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Heart Murmurs in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
      </Typography>
      <Typography>
      <h3><a href="https://petparenthandouts.com/vhd" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Valvular Heart Disease Handout</a> <span style={{fontWeight: "500"}}>from PetParentHandouts.com</span></h3>
      </Typography>
      <Typography>
      <h3><a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/cardiology-CVDHalfBooklet.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Chronic Degenerative Valve Disease Handout</a> <span style={{fontWeight: "500"}}>from Texas A&M Veterinary Medical Teaching Hospital</span></h3>
      </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography style={{fontSize: ".8rem"}}><sup>1</sup><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6524084/#jvim15488-bib-0025" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>ACVIM consensus guidelines for the diagnosis and treatment of myxomatous mitral valve disease in dogs. 2019.</a></Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography style={{fontSize: ".8rem"}}><sup>2</sup><a href="http://cardiaceducationgroup.org/wp-content/uploads/2016/12/CEG_Recommendations_EPIC_121316.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>The EPIC Trial: Pimobendan in Preclinical Myxomatous
Mitral Valve Disease. December 2016. Cardiac Education Group.</a></Typography>
    </Grid>
      </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
