import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import AdditionalResources from './subtopics/AdditionalResources';
import FelineCancers from './subtopics/FelineCancers';
import CanineCancers from './subtopics/CanineCancers';

import Adipose100 from './images/adipose100x.jpg'

import HistopathPlasmacytoma from './images/histopathPlasmacytoma.jpg';
import CytologyPlasmacytoma from './images/cytologyPlasmacytoma.jpg';
import Coring from './images/coring.gif'
import SlideSpray from './images/slideSpray.gif'
import Smear from './images/smear.gif'
import MCT from './images/mastCellTumor.jpg'
import PeripheralBlood from './images/peripheralBlood.jpg'
import HemangiomaHisto from './images/hemangioma.jpg'





const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Cancer";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Cancer</h1>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Key Points for Communication</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
              <Typography>
              Dogs and cats get cancer. Cancer comes from a bad population of the body's own cells. Some cancers are benign, or cause no harm, but some are malignant and do cause harm.  It is important to keep in mind that cancer is an individual disease&mdash;even with all we know about different cancer types, cancer often behaves differently in  different individuals.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              Cancer development is multifactorial and multistep. Many factors contribute to the development of cancer, including genetic factors, environmental factors, hormones, viruses, parasites, and chronic inflammation or infection. The body has many defense mechanisms to protect against cancer, so several steps are usually required for cancer to successfully establish itself and subsequently threaten overall health.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              Knowing the type of cancer and characterizing it by "grade" and "stage" can help inform us how the cancer might behave and what we should or could do about it.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              Cancer treatment options include chemotherapy, surgical oncology, and radiation therapy. Not all of these treatments are appropriate and available for every cancer, pet, family, or situation.  It is important to keep the goals and values of the family in mind before and after diagnosis.  We do not have to pursue a diagnosis for every mass.  If we do, and treatments are elected, it should be with an informed understanding of the range of possible outcomes and side effects.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <h3><a href="https://petparenthandouts.com/cancer" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cancer in Dogs Handout</a> from PetParentHandouts.com</h3>
              </Typography>
              </Grid>
            </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Mapping Lumps & Bumps</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
            <Grid item xs={12}>
            <Button component={Link} to="/tumor-map" variant="outlined" color="" className={classes.button} target="_blank" rel="noopener noreferrer">
            Canine Tumor Map
            </Button>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Dogs often get lumps and bumps on or just under the skin.  In general, things that grow fast or look "angry" are more concerning and should be investigated.  If any lump is keeping the patient up at night (scratching, chewing, licking) or pet parent up at night (worrying about it), it should be investigated.  This investigation often starts with fine needle coring and cytology (poking it with a needle to collect cells and looking under a microscope).  Fine needle coring is often referred to as fine needle aspiration (FNA), but most masses do not need to be aspirated and coring without aspiration is preferred to collect cells.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            Sometimes old dogs get a lot of lumps and bumps, and it becomes important to track changes over time and keep record of which have been poked and what was seen under the microscope.  A tumor map can be helpful to track the lumps and bumps.  These maps can be sent home and used as often as necessary to keep track of changes.  Record location, size, and gross appearance.
            </Typography>
            </Grid>
            </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Fine Needle Aspiration/Coring</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>This technique is often the first step when <span style={{fontWeight: "700"}}>evaluating the cells that make up masses</span>.</span> It is easily performed in the veterinary hospital, and some cell types can be readily identified using Diff-Quik stain.  Slides can be sent out for special staining and evaluation by a pathologist.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>The goal of fine needle coring is to capture some of the cells that make up a tumor.  This can tell us more about how the tumor might behave and how we might approach surgical removal.</span> Many types of cancer cells "exfoliate" well and will be captured by coring.  Certain cancer cells do not exfoliate well (those of mesenchymal origin as one example).
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Fine Needle Coring</span>
                <img src={Coring} style={{borderRadius:"8%", maxWidth: "96%"}} alt="woodpecker technique for coring a mass"/>
                <div>Only a tiny sample of a much larger mass will be obtained.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slide transfer</span>
                <img src={SlideSpray} style={{borderRadius:"8%", maxWidth: "96%"}} alt="spraying the aspirate onto a slide"/>
                <div>Attach a syringe with the plunger back and then shoot the sample onto a slide.  This sample has a lot of blood, but many samples will produce a much smaller spray.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Smear thick samples</span>
                <img src={Smear} style={{borderRadius:"8%", maxWidth: "96%"}} alt="smearing the spray"/>
                <div>Thick samples will not dry well and will be too thick to evaluate under the microscope.  Smear these samples into a thin layer.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>Stain one or two of the slides in the "clean" Diff-Quik</span> (ideally not the same stains used for ear cytology and other samples rich with debris or bacteria).  Even if planning on sending the samples out, at least one slide should be evaluated in house to make sure appropriate cells were captured.
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span>
                <img src={MCT} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells under the microscope"/>
                <div>These mast cells can be easily identified.  This helps inform surgical planning.  They do not tell us what "grade" the tumor is&mdash;that requires histopathology.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Adipose (fat) cells</span></div>
                <img src={Adipose100} style={{borderRadius:"50%", maxWidth: "96%"}} alt="adipose cells under the microscope"/>
                <div>This sample contained only fat cells and is consistent with a lipoma.  Liposarcoma cannot be ruled out by cytology alone</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>A<span style={{fontWeight: "700"}}> lipoma</span> is a common benign tumor of older dogs.  These are often soft, subcutaneous tumors that are freely movable or adhered to underlying tissue.</span>  The range in size, but generally grow slowly.  Lipoma's are much more common than liposarcomas (potentially malignant fatty tumors), but the two cannot be differentiated by cytology alone.  It is not appropriate to diagnose a "lipoma" by palpation and gross appearance alone.  Tumors that change quickly, get bigger and smaller, or every appear red, ulcerated, or bleeding should be evaluated or re-evaluated by fine needle coring and cytology or surgically removed for histopatholgy.
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Blood</span>
                <img src={PeripheralBlood} style={{borderRadius:"50%", maxWidth: "96%"}} alt="peripheral blood under the microscope"/>
                <div>Some highly vascular skin masses may not be appropriate for fine needle coring.  This sample is consistent with peripheral blood.  Histology is often needed to differentiate hemangiosarcoma from other more benign vascular skin tumors.</div>
                </Paper>
              </Grid>
            </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Biopsy and Histopathology</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Typography>
                    A biopsy takes a larger piece of tissue from the tumor (incisional) or takes the entire tumor (excisional).  This allows better evaluation of the entire tumor than fine needle coring&mdash;which relies on a few cells falling off (exfoliating) into the needle.  Some tumor cells cannot be captured by coring with a needle.  Some tumors require histopathology to understand how they typically behave (and inform prognosis).  Additionally, a histopathology allows evaluation of tumor margins&mdash;whether or not we got all of the tumor or left some behind.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Plasmacytoma (cytology)</span></div>
                  <img src={CytologyPlasmacytoma} style={{borderRadius:"50%", maxWidth: "96%"}} alt="cytology of plasmacytoma"/>
                  <div>Here is a cytology sample of a plasmacytoma (obtained by fine needle coring). The round cells cluster together in sheets, but the architecture cannot be evaluated.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Plasmacytoma (histopathology)</span></div>
                  <img src={HistopathPlasmacytoma} style={{borderRadius:"3%", maxWidth: "96%"}} alt="histology of plasmacytoma"/>
                  <div>The architecture of the plasmacytoma can be evaluated on histopathology.</div>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (cytology)</span></div>
                  <img src={PeripheralBlood} style={{borderRadius:"50%", maxWidth: "96%"}} alt="peripheral blood"/>
                  <div>Hemangiomas and hemangiosarcomas are vascular tumors. Fine needle coring will look like peripheral blood.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hemangioma (histopathology)</span></div>
                  <img src={HemangiomaHisto} style={{borderRadius:"3%", maxWidth: "96%"}} alt="histopath of hemangioma"/>
                  <div>Notice that histopathology can show architecture and margins.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography className={classes.left} gutterBottom>
                    <a href="https://peer.tamu.edu//vetschool.asp" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>Histology examples from Dr. Larry Johnson</h3></a>
                    <span>Histology examples of normal tissue specimens</span>
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Common Cancers in Dogs</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <CanineCancers/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Common Cancers in Cats</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <FelineCancers/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Additional Resources</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <AdditionalResources/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
