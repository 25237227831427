import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Yeast4 from '../images/skinCytology/4yeast.jpg';
import Cocci4Yeast2 from '../images/skinCytology/4cocci2yeast.jpg';
import Cocci4Rods2 from '../images/skinCytology/4cocci2rods.jpg';
import Rods4Cocci1Yeast1 from '../images/skinCytology/4rods1cocci1yeast.jpg';
import Rods4 from '../images/skinCytology/4rods.jpg';
import otodectes from '../images/skinCytology/otodectes.jpg';
import cocciNeutrophils from '../images/skinCytology/cocciNeutrophils.jpg';
import demodex from '../images/skinCytology/demodex.gif';
import dss from '../images/skinCytology/dss.gif';
import sss from '../images/skinCytology/sss.gif';
import sarcoptes from '../images/skinCytology/sarcoptesDrawn.jpg';
import interdigitalTape from '../images/skinCytology/interdigitalTape.gif';
import inguinalTape from '../images/skinCytology/inguinalTape.gif';
import tapeYeast1 from '../images/skinCytology/tapeYeast1.jpg';
import tapeYeast2 from '../images/skinCytology/tapeYeast2.jpg';
import ghostYeast from '../images/skinCytology/ghostYeast.jpg';
import clawFoldSample from '../images/skinCytology/clawFoldSample.gif';
import clawFoldSmoosh from '../images/skinCytology/clawFoldSmoosh.gif';
import yeastClawFold from '../images/skinCytology/yeastClawFold.jpg';
import microsporum from '../images/skinCytology/microsporum.jpg';
import earRoll from '../images/skinCytology/earRoll.gif';
import direct from '../images/skinCytology/direct.gif';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  }
});

class DogFieldMedicine extends Component {


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.fullwidth}>


          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Ear Cytology</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Note <span style={{fontWeight: "700"}}>color, odor, and relative amount</span> of waxy debris in the ears.</span>  Brown waxy debris is most frequently seen with cocci bacteria, Malassezia (yeast), and ear mites, whereas Yellow to white color discharge may be associated with Pseudomonas or other rod-shaped bacteria.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Note if the ear canal is <span style={{fontWeight: "700"}}>narrowed, swollen, or red</span>.</span>  This indicates inflammation of the external canal, and may complicate visualization of the canal, sample collection, and treatment.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem"}}>Gently <span style={{fontWeight: "700"}}>collect a sample</span> of ear debris from each ear.</span>  This is best done with a cotton tipped applicator inserted gently into the external canal.  Ears are painful, and inflamed ear canals are easily traumatized.  Do not put your safety or your patients safety at risk.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Roll the cotton tips onto a glass slide</span>.  The samples are easiest to evaluate if you make two straight lines on the glass slide.  When you hold the frosted portion of the slide towards your body, the sample from the right ear should be rolled away from you on the right side of the glass slide.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Slide Preparation</span>
                  <img src={earRoll} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Rolling two straight lines makes it easier to evaluate quickly.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Stain the slide</span>.  There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>6.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Evaluate the slide under the microscope</span>.  Mites can be found with the 10x objective lens.  Yeast, cocci, and rods should be hunted at 1000x with a drop of immersion oil on the slide.  The number of yeast and cocci are assigned from 1+ to 4+.  There's no right or wrong number; 1+ means few and 3 or 4+ means many.
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Yeast at 1000x</span>
                <img src={Yeast4} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                4+ yeast
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cocci and Yeast 1000x</span>
                <img src={Cocci4Yeast2} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                4+ cocci, 2+ yeast
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mixed Bacteria at 1000x</span>
                <img src={Cocci4Rods2} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                4+ cocci, 2+ rods
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Rods at 1000x</span>
                <img src={Rods4} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                3+ rods
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Rods, Cocci, & Yeast at 1000x</span>
                <img src={Rods4Cocci1Yeast1} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                4+ rods, 1+ cocci, 1+ yeast
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Ear Mite at 100x</span>
                <img src={otodectes} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                Otodectes
                </Paper>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Acetate Tape Cytology</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>This technique is the mainstay of looking for <span style={{fontWeight: "700"}}>yeast in body folds</span>.</span> It will also pick up cocci (round bacteria), but these should not be over-interpreted as they are often confused with the acetate granules of the tape itself.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>Tape cytology is especially useful for <span style={{fontWeight: "700"}}>interdigital spaces (between paw pads), lip folds, and axillary and inguinal regions (armpits and groin)</span>.</span> These areas are often relatively dry and waxy, and the tape will pick up yeast (Malassezia) and cocci.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Wrap a piece of <span style={{fontWeight: "700"}}>acetate tape over your finger</span>.  Keep the sticky side out and use it to sample around the patient.</span> Multiple sites (often armpits and groin) or interdigital spaces (get between the toes on several feet) can be sampled with the same piece of tape.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Interdigital Tape</span>
                  <img src={interdigitalTape} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Get after it.  You're trying to capture the yeast.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Inguinal Tape</span>
                  <img src={inguinalTape} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Inguinal and axillary regions are classic for yeast overgrowth.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}><span style={{fontWeight: "700"}}> Press the tape onto the glass slide</span>.</span>  This bit can take some practice.  If the tape still has good stick and you press too hard, it will not take up purple well.  If the tape has almost no stick left you may need to push it on there with maximum force so it doesn't fall off in the stain.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Stain the slide in the purple step only</span>. That's right; skip the first two steps of Diff-Quik and go straight for the purple.  Let it sit in there for a couple of minutes, rinse it off, and press out the excess stain between a couple of paper towels.  The tape might be a bit wavy, and that's okay.  If needed, a drop of oil under the tape can help smooth it out.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Evaluate the slide under the microscope</span>.  The 10x lens (100x magnification) can be used to find an area rich with purple stuff. Find a good spot, add a drop of immersion oil over the tape, and go to 100x. <span style={{fontSize: "1.2rem"}}>You may have to zoom in and out frequently to stay at the level of the collected sample.</span> The fine acetate granules let you know you're in the right plane.
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span>
                <img src={tapeYeast1} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                They're trying to hide out under that flake of skin.
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span>
                <img src={tapeYeast2} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                This is why the dog was so itchy!
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Ghost Yeast at 1000x</span>
                <img src={ghostYeast} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                Notice how the stain makes some yeast look like ghosts.  Same old yeast, just in ghost form.
                </Paper>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Claw Fold Cytology</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>Dogs with itchy feet often have <span style={{fontWeight: "700"}}>yeast overgrowth at the base of their claws</span>.</span> Dogs with salivary staining (brown discolored paw fur), reported allergies, or reported foot licking and chewing should have their claw folds examined for yeast overgrowth.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Use the <span style={{fontWeight: "700"}}>wooden end of a cotton-tipped applicator</span> to collect the waxy sample from the base of the claw.</span> Multiple claws can be sampled for the same slide.  If there's not much waxy debris, you can sample with the moistened cotton end.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Claw fold sample collection</span>
                  <img src={clawFoldSample} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Gently scrape off those waxy flakes.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem"}}> As soon as you have a small amount of sample, <span style={{fontWeight: "700"}}> transfer it to the slide</span>.  Smoosh all the collected waxy debris into a small area near the frosted portion at the base of the glass slide.</span>  You need to be aggressive and smoosh it good so it doesn't fall off.  A small amount of sample can host a yeast party.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Claw fold slide preparation</span>
                  <img src={clawFoldSmoosh} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Smoosh like you mean it.</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Stain the slide</span>.  There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Evaluate the slide under the microscope</span>.  The 10x lens can be used to find the area where you smooshed the samples. Find that spot, add a drop of immersion oil over the tape, and go to the 100x lens.
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Malassezia (Yeast) at 1000x</span>
                <img src={yeastClawFold} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                We should treat this yeast infection.
                </Paper>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Direct Impression Cytology</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>This technique is best for moist or wet skin lesions.</span> You simply press the glass slide onto the skin you are trying to sample.  A little wiggle or rotation of the slide can help capture more stuff.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>Direct impression cytology is not appropriate for all body locations.</span> Be cautious around lip folds, paws, or with wiggly patients; acetate tape cytology may be a better choice in these situations.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Carefully <span style={{fontWeight: "700"}}>press the slide onto the site you are sampling</span>.</span> These slides can break if you press too hard.  Don't cut yourself or your patient.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Gently <span style={{fontWeight: "700"}}>wiggle or rotate</span> the slide a little to collect more sample.</span>  Then remove it from the skin.  You should be left with a nice smear on the slide.
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sample Collection</span>
                <img src={direct} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                <div>
                Nothing to it. Just don't break the glass slide on the patient.
                </div>
                </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Stain the slide</span>.  There is no need to heat fix the sample to the slide; the first step of Diff-Quik is a fixative.  You can dunk the sample 10-20 times in each step or let it sit for 30+ seconds before moving to the next stain.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>6.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Evaluate the slide under the microscope</span>.  The 10x lens can be used to find an area rich with purple stuff. Put a drop of oil on the slide and move to the 100x lens to hunt bacteria (often cocci) and inflammatory cells (often neutrophils).
                </Grid>
                <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cocci & Ruptured Inflammatory Cells</span>
                <img src={cocciNeutrophils} style={{borderRadius:"50%", maxWidth: "96%"}}/>
                Lots of cocci and busted inflammatory cells (strings of purple cotton candy are smeared nuclear material)
                </Paper>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Deep Skin Scraping</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>This technique is often used to look for <span style={{fontWeight: "700"}}>Demodex mites</span>.</span> These mites hang out deep in the skin around hair follicles and cause patchy, assymettrical hairloss that may progress to a generalized, symmetrical pattern (especially in puppies).
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontSize: "1.2rem"}}>Black heads (<span style={{fontWeight: "700"}}>comodones</span>) or hair loss on the top of the feet (<span style={{fontWeight: "700"}}>dorsal pedal alopecia</span>) are good reasons to do a deep skin scrape.</span> Demodex mites love comodones and are often associated with dorsal pedal alopecia.  They also frequently present with hair loss around the eyes (periocular alopecia) or on the face.  Little red bumps on the inside surface of puppy ears (papules on the concave surface of the pinna) may also appear with Demodex overgrowth.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Apply a few drops of <span style={{fontWeight: "700"}}>mineral oil</span> onto the site you will sample.</span>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Use the <span style={{fontWeight: "700"}}>small end of a metal spatula</span> to scrape with the direction of the hair until you see blood (capillary oozing).</span>  This is not painful to your patient when performed correctly.  Some use a scalpel blade and scrape perpendicular to the edge of the blade.  This is not recommended, as it can easily cut a wiggly patient.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Deep Skin Scrape Technique</span>
                  <img src={dss} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>Scrape with the direction of the hair until you see capillary oozing (blood)</div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Spread the sample onto a slide.</span> The oil, debris, and blood can be collected with the wide side of the spatula and spread onto the slide.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem"}}>Ideally,<span style={{fontWeight: "700"}}> collect 3-5 samples</span> from different sites.</span> This makes it more likely you will find the mite.  All of your samples can be combined on a single slide.
                </Grid>
                <Grid item xs={12} md={4}>
                  <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Examine the slide under the microscope</span>.  No need to stain; this would just wash off the oil and mites.  Extreme cases will have a Demodex party under the microscope.
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper style={{textAlign: "center", margin: "auto"}}>
                  <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Demodex at 100x</span>
                  <img src={demodex} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                  <div>The classic cigar-shaped Demodex canis mite wiggling its stubby legs</div>
                  </Paper>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Superficial Skin Scraping</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>This technique is often used to look for <span style={{fontWeight: "700"}}>Sarcoptes scabiei mites</span>.</span> These mites hang out on the surface and are hard to find.  You may also find Otodectes (ear mites), Cheyletiella, or occasionally Notoedres.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>Sarcoptes scabiei mites are usually <span style={{fontWeight: "700"}}>extremely itchy</span>.</span> Itchy dogs that scratch a lot will often be most affected on the <span style={{fontWeight: "700"}}>lateral trunk and hocks</span> (that joint below the knee).
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Apply some <span style={{fontWeight: "700"}}>mineral oil</span> onto the site you will sample.</span>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Use the <span style={{fontWeight: "700"}}>broad end of a metal spatula</span> to scrape large swaths of the patient.</span>  The elusive scabies mite is hard to catch⁠—the more sample, the better.
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Superficial Skin Scrape Technique</span>
                <img src={sss} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                <div>Desperately trying to catch the Sarcoptes scabiei mite</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Spread the sample onto a slide.</span> The oil and debris can be collected onto the spatula and spread onto the slide as though you were buttering toast.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Examine the slide under the microscope</span>.  No need to stain; this would just wash off the oil and mites. Examine the entire sample at 100x. If you do find scabies mites, they are causing the itch.  If you do not find scabies mites, you cannot rule them out⁠—these mites are hard to catch.
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sarcoptes scabiei (scabies mite)</span></div>
                <img src={sarcoptes} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                <div>Hard to find. Harder to photograph.</div>
                </Paper>
              </Grid>
            </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Dermatophytes (Ringworm)</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>Dermatophytes are especially common in cats. <span style={{fontWeight: "700"}}>Long-haired cats and dogs that live with long-haired cats</span> should be sampled for dermatophytes if they present with a spot of hair loss.</span> Lesions are often patchy and asymmetrical. You can examine the pet with a Wood's lamp (UV/black light), but only 50% of Microsporum canis will fluoresce.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Use a clean forceps (tweezers or grabbers) to <span style={{fontWeight: "700"}}>pluck some hairs</span> from the edge of the spot with hair loss.</span> A fresh toothbrush can also be used to collect hairs for send-out fungal cultures.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Add the hairs to <span style={{fontWeight: "700"}}>the dermatophyte test media (DTM)</span></span>. This is the little jar or plate with yellow Jell-O stuff in it.  You may also want to add some hairs to a white top tube for send-out culture.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Let the jar sit at room temperature and watch for a color change.</span> You can leave it for a couple of weeks, but most pathologic dermatophytes will turn the media red within the first 6 days.
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span><span style={{fontSize: "1.2rem"}}> If the growth media turns red, <span style={{fontSize: "1.2rem", fontWeight: "700"}}>a tape prep of the growth must be examined under the microscope</span>.</span>  Lots of other things can turn this media red, so you need to look for the dermatophytes to confirm that these caused the skin lesion.
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Microsporum canis</span>
                <img src={microsporum} style={{borderRadius:"8%", maxWidth: "96%"}}/>
                <div>Other Microsporum species look similar</div>
                </Paper>
              </Grid>
            </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>


        </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
