import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import microfilaria from '../images/microfilaria.gif';
import heartImage from '../images/bayerHeart.jpg';
import heartwormMap from '../images/heartwormMap.jpg';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Typography className={classes.contentBlock}>
      <p><span style={{fontWeight: "700"}}>Dogs should be tested for heartworm disease every year.</span></p>
      <p>This is the current recommendation of the American Heartworm Society.  Heartworm antigen blood testing looks for evidence of adult female worms in the dogs heart.  Microfilaria testing looks for baby parasites in the dog's blood.</p>

      <p><span style={{fontWeight: "700"}}>A single delayed or skipped dose of heartworm preventative medication can lead to infection.</span></p>
      <p>The preventative medications target the young parasites in the blood.  Once these reach a certain age, the monthly preventative medications will not kill them.  Dogs that miss doses should be retested 7-9 months after the missed dose.</p>

      <p><span style={{fontWeight: "700"}}>Cats are also at risk of heartworm infection and should be on monthly preventative medication.</span></p>
      <p>Healthy cats are not routinely tested for heartworm disease, since the available tests are less reliable.  Infected cats can become very sick, and adult heartworms cannot be treated in cats.  Many of the monthly heartworm preventatives for cats have the added benefit of protecting against certain fleas, ticks, mites, and intestinal parasites.</p>

      <p><span style={{fontWeight: "700"}}>Heartworm infection is spread by mosquitoes.</span></p>
      <p>Infected dogs give the parasite to mosquitoes, and the mosquitoes pass the parasite to other dogs.  Although the spread decreases in the winter months, infected mosquitoes exist even in cold climates.</p>


      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <a href="https://www.animalhealth.bayer.ca/en/flash/videos/BAYER_MULTI_HEARTWORM_EN.php" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Heartworm Video</span>
        <img src={heartImage} style={{borderRadius:"2%", maxWidth: "96%"}} alt="heart"/>from Bayer Animal Health</a>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Microfilaria</span>
        <img src={microfilaria} style={{borderRadius:"2%", maxWidth: "96%"}} alt="microfilaria"/> The baby parasites in the blood
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <a href="https://www.heartwormsociety.org/veterinary-resources/incidence-maps" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Heartworm Incidence Maps</span>
        <img src={heartwormMap} style={{borderRadius:"2%", maxWidth: "96%"}} alt="map of heartworm prevalence"/>from the American Heartworm Society</a>
        </Paper>
        </Grid>

      </Grid>
      </Typography>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
