import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Heartworm from '../../bloodandurine/subtopics/Heartworm';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {


  render() {
    const { classes } = this.props;
    return (
      <div>
      <div className={classes.nintysix} style={{paddingBottom: "1rem"}}>
        <Paper className={classes.tablePaper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{fontWeight: "700"}}>Defect</TableCell>
              <TableCell align="center" style={{fontWeight: "700"}}>Location</TableCell>
              <TableCell align="center" style={{fontWeight: "700"}}>Timing</TableCell>
              <TableCell align="center" style={{fontWeight: "700"}}>Pulses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">PDA</TableCell>
              <TableCell align="center">left basilar</TableCell>
              <TableCell align="center">continuous</TableCell>
              <TableCell align="center">bounding (low diastolic pressure)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">PS</TableCell>
              <TableCell align="center">left basilar</TableCell>
              <TableCell align="center">systolic</TableCell>
              <TableCell align="center">normal</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">SAS</TableCell>
              <TableCell align="center">left basilar</TableCell>
              <TableCell align="center">systolic</TableCell>
              <TableCell align="center">normal to &darr;</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </Paper>
      </div>
      <div className={classes.fullwidth}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Patent Ductus Arteriosus (PDA)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            The ductus arteriosus is a normal blood vessel in puppies before they are born.  It should close at or shortly after birth.  When it doesn't close, blood is shunted down this extra path and makes the heart work harder.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <h3><a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/PDA.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Patent Ductus Arteriosus Handout</a> <span style={{fontWeight: "500"}}>from Texas A&M University</span></h3>
          </Typography>
          <Typography>
          <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8604573" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Patent Ductus Arteriosus in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
          </Typography>
          </Grid>

          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Pulmonic Stenosis</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            As blood is pumped out of the right side of the heart towards the lungs, it encounters resistance due to thickened, fused valve leaflets or abnormal narrowing.  This can often be heard as a murmur in the left axillary (armpit) region near the heart base.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <h3><a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/PS.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Pulmonic Stenosis Handout</a> <span style={{fontWeight: "500"}}>from Texas A&M University</span></h3>
          </Typography>
          <Typography>
          <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952783" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Pulmonic Stenosis in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
          </Typography>
          </Grid>
        </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Subaortic Stenosis</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography gutterBottom>
            As blood is pumped out of the left side of the heart towards the body, it encounters resistance due to abnormal narrowing.  This can often be heard as a murmur in the left axillary (armpit) region near the heart base.
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography>
          <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952497" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Subaortic Stenosis in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
          </Typography>
          </Grid>
        </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      </div>
      </div>

  );
}
}

DogFieldMedicine.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
