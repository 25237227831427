import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import TearTestRight from './images/TearTestRight.gif';
import TearTestReading from './images/TearTestReading.gif';
import FluoresceinStain from './images/FluoresceinStain.gif';
import UlcerSimple from './images/UlcerSimple.jpg';
import Tonometry from './images/Tonometry.gif';
import EyeExamHold from './images/EyeExamHold.gif';
import AdditionalResources from './subtopics/AdditionalResources';

import Cataracts from './subtopics/Cataracts';
import CornealUlcer from './subtopics/CornealUlcer'
import DryEye from './subtopics/DryEye'
import FelineHerpes from './subtopics/FelineHerpes'
import Glaucoma from './subtopics/Glaucoma'
import Proptosis from './subtopics/Proptosis'




const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Eyes";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Eyes</h1>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Eye Exam</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
              <Grid item xs={12}>
              <Button component={Link} to="/eye-form" variant="outlined" color="" className={classes.button} target="_blank" rel="noopener noreferrer">
              Ophtho Exam Form
              </Button>
              </Grid>
              <Grid item xs={12} md={4} gutterBottom>
                <span style={{fontSize: "1.2rem"}}>The<span style={{fontWeight: "700"}}> Schirmer Tear Test (STT), Fluorescein Stain, and Tonometry (Tono)</span> are considered the minimum diagnostics required for a complete eye exam.</span> They should be considered for all patients who present with an eye problem.  STT and tonometry may not be performed in certain cases (fragile eyes).  STT is rarely performed in cats.
              </Grid>
              <Grid item xs={12} md={4} gutterBottom>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Holding Technique</span></div>
                <img src={EyeExamHold} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>Gentle restraint with one pointer finger on top of the head and the other thumb and pointer finger under the chin can facilitate examination of the eye.  Work at the pace of your patient.</div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Schirmer Tear Test</span></div>
                <img src={TearTestRight} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>This test measures the aqueous portion of the tear film.  Dogs that underproduce aqueous tears often produce excess mucous.  </div>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>This test should be performed first, before any eye drops or treatments are performed.</span> A gloved hand should be used to bend the end of the test strip at the notch.  Oils on your finger could alter the reading.  Apply the strip so it touches the cornea in the mid to lateral portion.  If applied medially, the third eyelid may decrease the result.  The strip should stay in for <span style={{fontWeight: "700"}}>60 seconds</span> if possible.  You may need to gently help hold it in or prevent the patient from shaking it out.  Some dogs will tolerate placement in both eyes at the same time.
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Schirmer Tear Test Result</span></div>
                <img src={TearTestReading} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div><span style={{fontWeight: "700"}}>Normal dogs have an STT of &ge;15.</span>  10-15 may suggest keratoconjunctivitis sicca (KCS, dry eye). &le;10 confirms KCS.  This dog has KCS.</div>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Fluorescein Stain</span></div>
                <img src={FluoresceinStain} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>This test helps identify corneal ulceration.</div>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontSize: "1.2rem"}}>When the surface layer of the cornea (corneal epithelium) is broken, the next layer (corneal stroma) will pick up stain and fluoresce bright green under cobalt blue light.  </span>Descemet's membrane is the bottom layer of the cornea.  This layer will not pick up stain.  A deep ulcer that extend to this bottom layer is termed a 'descemetocele.'  A drop of ophthalmic proparacaine (a topical anesthetic) can be put on the tip of the fluorescein strip and applied to each eye.  Because proparacaine can cause minor corneal erosions that may be misinterpreted as ulceration, you should attempt to touch the strip to the sclera (white part) above the cornea.  You may need to rinse with contact solution or sterile eye flush since the tear film itself can take up stain.
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Superficial Corneal Ulcer</span></div>
                <img src={UlcerSimple} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>This superficial corneal ulcer took up stain.  This is not the patient with KCS from the previous video.  Although KCS can lead to corneal ulceration, chronic KCS generally does not.</div>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
                <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Tonometry</span></div>
                <img src={Tonometry} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>This test should be performed after numbing the eye.</div>
              </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.2rem"}}>Tonometry is used to measure pressure in the eye (intraocular pressure, IOP).</span> This is used to diagnose glaucoma (the most common cause of blindness). It is not performed in fragile eyes. It should be performed before pupil dilation (tropicamide can increase intraocular pressure). <span style={{fontWeight: "700"}}>The lowest reading is most accurate. 10 - 20 mmHg is normal. It should always be ≤ 25 mmHg. 8 mmHg difference between eyes may be abnormal. ≥ 25 mmHg with vision loss is glaucoma. Low intraocular pressures are consistent with uveitis.</span>
              </Grid>
              <Grid item xs={12} md={4}>
                <span style={{fontWeight: "700", fontSize: "1.2rem"}}>Proper patient restraint is important, as applying pressure to the neck can artificially increase IOP readings.</span> If using the tonopen as pictured, you should hold it perpendicular to the surface of the cornea and push just hard enough to hear a small click from the device.  Push too hard, and you will artificially increase the IOP.  If it seems not to be reading, it is possible the pressures are too low to read (common with uveitis).  The Tonovet rebound tonometer is a different tool that is more accurate when pressures are low.
              </Grid>

              </Grid>

            </ExpansionPanelDetails>
          </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Cataracts (and Nuclear Sclerosis)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
          <Cataracts/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Corneal Ulcer</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
          <CornealUlcer/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Dry Eye (keratoconjunctivitis sicca)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <DryEye/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Feline Herpesvirus Keratoconjunctivitis</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <FelineHerpes/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Glaucoma</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <Glaucoma/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Proptosis of the Globe (eye popped out)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <Proptosis/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Additional Resources</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <AdditionalResources/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
