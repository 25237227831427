import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class AdditionalResources extends Component {

  render() {
    const { classes } = this.props;
    return (

      <div>
        <Grid container spacing={24} alignItems="flex-start">
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://www.aaha.org/globalassets/02-guidelines/canine-vaccination/vaccination_recommendation_for_general_practice_table.pdf" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>AAHA Canine Vaccination Guidelines</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://catvets.com/guidelines/practice-guidelines/feline-vaccination-guidelines" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>AAFP Feline Vaccination Guidelines</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://www.wsava.org/Guidelines" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>WSAVA Global Guidelines</h3></a>
          </Typography>
          </Grid>
        </Grid>
      </div>

  );
}
}

AdditionalResources.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdditionalResources);
