import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router
} from 'react-router-dom'

import ScrollToTop from './ScrollToTop';


import James from "./James";
import Skin from "./training/topics/dermatology/SkinMainPage";
import ItchScale from "./training/topics/dermatology/subtopics/ItchScale";

import Anesthesia from "./training/topics/anesthesia/AnesthesiaMainPage";
import BloodAndUrine from "./training/topics/bloodandurine/BloodAndUrineMainPage";
import Neuro from "./training/topics/neuro/NeuroMainPage";
import NeuroForm from "./training/topics/neuro/subtopics/NeuroForm";

import Dentistry from "./training/topics/dentistry/DentistryMainPage";
import Emergency from "./training/topics/Emergency/EmergencyMainPage";
import Eyes from "./training/topics/eyes/EyesMainPage";
import EyeForm from "./training/topics/eyes/subtopics/EyeForm";
import Heart from "./training/topics/heart/HeartMainPage";
import Onco from "./training/topics/cancer/CancerMainPage"
import TumorMap from "./training/topics/cancer/subtopics/TumorMapForm";
import Nutrition from "./training/topics/nutrition/NutritionMainPage";
import MusclesAndBones from "./training/topics/musclesandbones/MusclesAndBonesMainPage";

import Vaccines from "./training/topics/vaccines/VaccinesMainPage";

import VetNurseGuide from "./training/VetNurseGuide"

import Communication from "./communication/CommunicationMain"

import CongestiveHeartFailureQS from "./quicksheets/CongestiveHeartFailureQS"

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path='/' component={VetNurseGuide} />
        <Route exact path="/anesthesia" component={Anesthesia} />
        <Route exact path="/bloodandurine" component={BloodAndUrine} />
        <Route exact path="/brainandspinalcord" component={Neuro} />
        <Route exact path="/neuro-form" component={NeuroForm}/>
        <Route exact path="/cancer" component={Onco} />
        <Route exact path="/tumor-map" component={TumorMap} />


        <Route exact path="/dentistry" component={Dentistry} />
        <Route exact path="/emergency" component={Emergency} />
        <Route exact path="/eyes" component={Eyes} />
        <Route exact path="/eye-form" component={EyeForm} />
        <Route exact path="/heart" component={Heart} />
        <Route exact path="/nutrition" component={Nutrition} />
        <Route exact path="/vaccines-and-wellness" component={Vaccines} />
        <Route exact path="/muscles-and-bones" component={MusclesAndBones} />

        <Route exact path="/skin" component={Skin} />
        <Route exact path="/itch-scale" component={ItchScale} />


        <Route exact path="/vet-nurse-guide" component={VetNurseGuide} />
        <Route exact path="/communication" component={Communication} />

        <Route exact path="/chf" component={CongestiveHeartFailureQS} />
        <Route render={() => <VetNurseGuide/>} />
      </Switch>
    </ScrollToTop>
  </Router>
)

export default Routes
