import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import cataract from '../images/cataract.jpg';
import nuclearSclerosis from '../images/nuclearSclerosis.gif';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Grid container spacing={4}>
      <Grid item xs={12} md={4} gutterBottom>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Cataract</span></div>
        <img src={cataract} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>A cataract is an opacity of the lens.  This cataract is complete and affects the entire lens, but this is not always the case.</div>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} gutterBottom>
      <Typography gutterBottom>
        Cataracts may be hereditary (have a genetic component), or may be caused by metabolic disorders (commonly diabetes mellitus).  Some are caused by trauma to the eye that damages the lens capsule.  Cataracts can lead to reflex uveitis and secondary glaucoma, which is painful.
      </Typography>
      </Grid>
      <Grid item xs={12} md={4} gutterBottom>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Nuclear Sclerosis</span></div>
        <img src={nuclearSclerosis} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>With age, the lens becomes more dense.  We see this as a greying of the lens that usually starts around age 6-7 years in dogs.  Do NOT confuse this with a cataract.  Notice that there is still a complete tapetal reflection when a light is directed through the lens and bounces back off the fundus.  This technique, termed 'retroillumination,' can differentiate cataracts from nuclear sclerosis.</div>
        </Paper>
      </Grid>
      </Grid>
      <Typography gutterBottom>
        For some dogs, cataracts can be treated by surgically replacing the lens with a prosthetic.  This is done by boarded veterinary ophthalmologists.  It can restore vision to dogs that have complete cataracts.
      </Typography>
      <Typography>
      <h3><a href="http://www.acvo.org/common-conditions-1/2018/3/1/cataracts" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cataracts Handout</a> from the ACVO</h3>
      </Typography>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
