import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import AdditionalResources from './subtopics/AdditionalResources'
import Vitals from './subtopics/Vitals';
import ConditionsBySystem from './subtopics/ConditionsBySystem';
import ClinicalSigns from './subtopics/ClinicalSigns';




const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class NeuroMainPage extends Component {
  componentWillMount() {
    document.title = "Emergency";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Emergency</h1>

            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Vital Signs</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.fullwidth}>
                <Vitals />
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Clinical Signs</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <div className={classes.fullwidth}>
              <ClinicalSigns/>
              </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Common Conditions by System</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <ConditionsBySystem/>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>Additional Resources</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <AdditionalResources/>
              </ExpansionPanelDetails>
            </ExpansionPanel>



      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

NeuroMainPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NeuroMainPage);
