import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {SketchField, Tools} from 'react-sketch';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';


import examImage from '../images/canine-tumor-map.jpg';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  divided: {
  margin: '20px 0px',
},
firstInput: {
  marginRight: '15px',
},
secondInput: {
  marginLeft: '15px',
}
});

const ranges = [
{
  value: '+',
  label: '+',
},
{
  value: '—',
  label: '—',
},
];

class EyeExamForm extends Component {

  state = {
    amount: '',
    password: '',
    weight: '',
    MenaceOD: '+',
    MenaceOS: '+',
    DazzleOD: '+',
    DazzleOS: '+',
    DPLROD: '+',
    DPLROS: '+',
    CPLROD: '+',
    CPLROS: '+',
    palpOD: '+',
    palpOS: '+',
    oculocephalicOD: '+',
    oculocephalicOS: '+',
    multiline: 'Controlled',
    showPassword: false,
    imageLoaded: false,
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  componentWillMount() {
    document.title = "Tumor Map";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.nintysix}>

              <h1>Canine Tumor Map</h1>
              <Grid container justify="flex-start" className={classes.root}>

                <Grid item className={classes.left} xs={12}>
                 <TextField
                          id="standard-textarea"
                          label="Date"
                          multiline
                          className={classes.textField}
                          margin="normal"
                        />
                </Grid>
                <Grid item className={classes.left} xs={12}>
                 <TextField
                          id="standard-textarea"
                          label="Name"
                          multiline
                          className={classes.textField}
                          margin="normal"
                        />
                </Grid>


                <Grid item xs={12}>
                  <Divider className={classes.divided}/>
                </Grid>



                <Grid item xs={12}>
                  <div className={classes.examImage}>
                    <div style={{position: 'relative', width: '100%', height: 'auto', maxWidth: '700px'}}>
                      <img style={{position: 'relative', width: '100%', height: 'auto', zIndex:'0'}} src={examImage} onLoad={this.handleImageLoaded.bind(this)} />
                      {this.state && this.state.imageLoaded &&
                        <SketchField style= {{position: 'absolute', zIndex:'10', display: 'block', Height: '100%', width: '100%', top: '0', left: '0', overflow: 'hidden'}}
                                     height="100%"
                                     tool={Tools.Pencil}
                                     lineColor='black'
                                     lineWidth={3}/>
                      }
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={classes.divided}/>
                </Grid>

                <Grid item className={classes.left} xs={12}>
                  <TextField
                    id="standard-full-width"
                    label="Description"
                    fullWidth
                    multiline
                    margin="normal"
                    className={classes.textField}
                  />
                </Grid>

              </Grid>

        </div>
      </div>

      );
  }
}

EyeExamForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EyeExamForm);
