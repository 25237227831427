import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';

import dog from './images/main_dog.jpg';
import free from './images/fear_free.jpg';
import james from './images/james.jpg';
import scottie from './images/scottie.png';
import frenchie from './images/frenchie.png';
import gridItem1 from './images/gridItem1.jpg';
import gridItem2 from './images/gridItem2.jpg';
import gridItem3 from './images/gridItem3.jpg';
import gridItem4 from './images/gridItem4.jpg';
import gridItem5 from './images/gridItem5.jpg';
import gridItem6 from './images/gridItem6.jpg';


import './App.css';

class James extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={dog} className="main-dog" alt="a dog looking up" />
          <div className="topper">
            <h1> James <br /> Oldeschulte</h1>
          </div>
        </header>
        <div className="Map">
          <h2>Practicing with compassion</h2>
          <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem1} alt="James Oldeschulte" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem2} alt="James Oldeschulte" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem3} alt="James Oldeschulte" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem4} alt="James Oldeschulte" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem5} alt="James Oldeschulte" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <img src={gridItem6} alt="James Oldeschulte" />
          </Grid>

          </Grid>
        </div>
        <div className="Free">
          <div className="centered">
            <div className="pad-james">
              <img src={james} className="james" alt="Photos of pets" />
            </div>
            <img src={free} className="fear-free" alt="Fear Free Certified Professional" />
          </div>
          <h2>Doctor of Veterinary Medicine<span><br />focused on promoting the human-animal bond</span></h2>
        </div>
        <div className="Projects">
          <img src={scottie} className="small-scottie" alt="scottie dog" />
          <a target="_blank" rel="noopener noreferrer" href="https://petparenthandouts.com/">Pet Parent Handouts</a>
          <br />
          <a target="_blank" rel="noopener noreferrer" href="https://dogscatskauai.com/">Dogs Cats Kaua'i</a>
          <br />
          <Link className="LinkButton" target="_blank" rel="noopener noreferrer" to="/vet-nurse-guide">Guide to Veterinary Nursing</Link>
          <br />
          <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCPl2mhYS6hU6rQWMwZFIZRw">YouTube Channel</a>
          <br />
          <a target="_blank" rel="noopener noreferrer" href="https://dogscatsmedicine.com/">Dogs Cats Medicine</a>
          <img src={frenchie} className="small-dog" alt="frenchie dog" />
        </div>
      </div>
    );
  }
}

export default James;
