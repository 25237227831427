import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Tonometry from '../images/Tonometry.gif';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <span style={{fontWeight: "700", fontSize: "1.2rem"}}>Glaucoma is the most common cause of blindness in animals.</span><span style={{fontSize: "1.2rem"}}> Increased intraocular pressure (IOP) damages the retina and optic nerve.  These eyes are often painful and red.</span>
      </Grid>
      <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontWeight: "700", fontSize: "1.6rem"}}>Tonometry</span></div>
        <img src={Tonometry} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>The lowest reading is most accurate. 10 - 20 mmHg is normal. It should always be ≤ 25 mmHg. 8 mmHg difference between eyes may be abnormal. ≥ 25 mmHg with vision loss is glaucoma.  Proper patient restraint is important, as applying pressure to the neck can artificially increase IOP readings.</div>
      </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <span style={{fontSize: "1.2rem"}}>With inflammation in the eye (uveitis) the IOP is often low.  If the inflammatory cells clog the place where normal intraocular fluid drains, this can cause increased IOP.</span>
      </Grid>
      </Grid>
      <Typography>
      <h3><a href="http://www.acvo.org/common-conditions-1/2018/2/2/glaucoma" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Glaucoma Handout</a> from the ACVO</h3>
      </Typography>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
