import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {

  render() {
    const { classes } = this.props;
    return (

      <div>
        <Grid container spacing={24} alignItems="flex-start">
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="http://vasg.org/index.htm" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>Veterinary Anesthesia & Analgesia Support Group</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="http://csuvth.colostate.edu/emergencydrugcalculator/" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>CSU Emergency Drug Calculator</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://www.banfield.com/exchange/practice-resources/anesthesia/overview" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>Banfield Anesthesia & Analgesia Books</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://petparenthandouts.com/anesthesia" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>Pet Parent Handouts: Canine Anesthesia</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://ivapm.org/wp-content/uploads/2017/03/Mathews_et_al-2014-Journal_of_Small_Animal_Practice-WSAVA-03.10.17.pdf" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>WSAVA Guidelines for Recognition, Assessment, and Treatment of Pain</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="https://acvaa.org/wp-content/uploads/2019/05/Treatment-of-Pain-in-Animals.pdf" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>ACVAA Position Paper on the Treatment of Pain in Animals</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="http://csu-cvmbs.colostate.edu/Documents/anesthesia-pain-management-pain-score-canine.pdf" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>CSU Canine Acute Pain Scale</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="http://csu-cvmbs.colostate.edu/Documents/anesthesia-pain-management-pain-score-feline.pdf" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>CSU Feline Acute Pain Scale</h3></a>
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography className={classes.left}>
            <a href="http://www.animalpain.com.br/en-us/avaliacao-da-dor-em-gatos.php" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>UNESP-Botucatu Multidimensional Composite Pain Scale</h3></a>
          </Typography>
          </Grid>
        </Grid>
      </div>

  );
}
}

DogFieldMedicine.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
