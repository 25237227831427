import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DietDCM from './dietDCM'


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <Typography gutterBottom>
        In dogs with dilated cardiomyopathy (DCM), the heart gets bigger to make up for lost pumping ability. This happens when the heart muscle gets weak or has irregular electrical activity. It often leads to heart failure, abnormal heart beats, and sudden death.
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>

      <Typography>
        Certain breeds are at increased risk of developing DCM including the Doberman, Great Dane, boxer, and many giant-breed dogs.
      </Typography>
      </Grid>

      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://petparenthandouts.com/dcm" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Dilated Cardiomyopathy Handout</a> <span style={{fontWeight: "500"}}>from PetParentHandouts.com</span></h3>
      </Typography>
      <Typography>
      <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952598" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Dilated Cardiomyopathy in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
      </Typography>
      <Typography>
      <h3><a href="https://vethospital.tamu.edu/hospital/wp-content/uploads/sites/15/2018/01/cardiology-DCMHalfBooklet.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Dilated Cardiomyopathy Handout</a> <span style={{fontWeight: "500"}}>from Texas A&M Veterinary Medical Teaching Hospital</span></h3>
      </Typography>
      </Grid>

      <Grid item xs={12} md={12}>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Diet-Associated Dilated Cardiomyopathy (DCM)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
          <DietDCM/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      </Grid>

      </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
