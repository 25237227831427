import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SebaceousAdenoma from '../images/sebaceousAdenoma.jpg'
import SebaceousCells from '../images/sebaceousCells.jpg'

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12}>
    <Typography gutterBottom>
      Sebaceous gland tumors grow outward (exophytic), are hairless (alopecic), and have a cauliflower-like surface.  They may become traumatized (by scratching or rubbing), but they are otherwise slow growing benign tumors of older dogs.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography>
    <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952215" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Benign Sebaceous Gland Tumors Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
    </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sebaceous Gland Tumor</span></div>
      <img src={SebaceousAdenoma} style={{borderRadius:"8%", maxWidth: "96%"}} alt="raised mass with rough surface"/>
      <div>Dermal, exophytic, alopecic mass with a cauliflower-like appearance&mdash;consistent with a sebaceous gland tumor</div>
      </Paper>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sebaceous Cells</span></div>
      <img src={SebaceousCells} style={{borderRadius:"50%", maxWidth: "96%"}} alt="sebaceous cells under the microscope"/>
      <div>Sebaceous cells make oils</div>
      </Paper>
    </Grid>
    </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
