import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12}>
      <Typography>
        <span style={{fontWeight: "700"}}>Feeding grain-free, boutique, exotic-protein, vegan, vegetarian, and home-cooked diets has been associated with DCM in breeds that are not usually at risk.</span>
      </Typography>
    </Grid>

      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://petparenthandouts.com/diet-associated-dcm" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Diet-Associated Dilated Cardiomyopathy Handout</a> <span style={{fontWeight: "500"}}>from PetParentHandouts.com</span></h3>
      </Typography>
      <Typography>
      <h3><a href="https://www.fda.gov/animal-veterinary/news-events/fda-investigation-potential-link-between-certain-diets-and-canine-dilated-cardiomyopathy" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>FDA Update</a> <span style={{fontWeight: "500"}}>from June 27, 2019</span></h3>
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography>
        <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Diets to Avoid:</span> The top brands associated with DCM in this FDA update include <span style={{fontWeight: "700"}}>Acana, Zignature, Taste of the Wild, 4Health, Earthborn Holistic, Blue Buffalo, Nature’s Domain, Fromm, Merrick, California Natural, Natural Balance, Orijen, Nature’s Variety, NutriSource, Nutro, and Rachael Ray Nutrish.</span>  Many of these are premium diets (and relatively expensive).  There is a lot of money in pet food (it's about 70% of the entire veterinary industry).  Many of our pets are on these diets, and many pet parents are bonded to these brands.
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography>
        <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Diets to Recommend:</span><span style={{fontWeight: "700"}}> Hill's, Purina, and Royal Canin (Iams and Eukaneuba are from the same manufacturer)</span> are the three big names in pet food.  They do most of the research on pet nutrition and do AAFCO feeding trials with their own diets.  For pet parents who wish to avoid these brands, a nutrition consult (with a boarded veterinary nutritionist, such as those offered by The Ohio State University) can be recommended.  For pet parents who wish to home-cook their pet's meals, Balance IT may be helpful.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://vet.osu.edu/vmc/companion/our-services/nutrition-support-service/nutrition-consult-request" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>The Ohio State University Nutrition Consult Request</a></h3>
      </Typography>
      <Typography>
      <h3><a href="https://secure.balanceit.com/recipegenerator_ver4/index.php?rotator=EZ" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Balance IT</a></h3>
      </Typography>
      </Grid>

      </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
