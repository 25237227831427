import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import toxocara from '../images/Toxocara.JPG';
import ancylostoma from '../images/Ancylostoma.JPG';
import trichuris from '../images/Trichuris.JPG';
import isospora from '../images/Isospora.JPG';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Typography className={classes.contentBlock}>
      <p><span style={{fontWeight: "700"}}>Depending on health and lifestyle, pets should be tested for intestinal parasites twice each year.<sup>1</sup></span></p>
      <p>Puppies and kittens should be tested 4 times in their first year of life.</p>

      <p><span style={{fontWeight: "700"}}>Broad spectrum intestinal parasite prevention should be used year round.</span></p>

      <p><span style={{fontWeight: "700"}}>Dog and cat poop can transmit disease to humans.</span></p>
      <p>Adult dogs and cats may show no signs of infection, so testing apparently healthy pets is still important.</p>

      <p><span style={{fontWeight: "700"}}>Fecal centrifugation is superior to other flotation techniques.<sup>2</sup></span></p>
      <p>This is the technique that should be used to look for hookworm, roundworm, and whipworm eggs.</p>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Roundworms</span>
        <img src={toxocara} style={{borderRadius:"5%", maxWidth: "96%"}} alt="toxocara ova"/>
        Toxocara canis ova.  Zoonotic (can be transmitted to humans).  Children are at increased risk of ingestion of the eggs and subsequent infection.  May cause visceral larva migrans and ocular larva migrans. <a href="https://www.cdc.gov/dpdx/toxocariasis/index.html" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>The CDC has additional information here.</a>
        </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hookworms</span>
        <img src={ancylostoma} style={{borderRadius:"5%", maxWidth: "96%"}} alt="ancylostoma ova"/>
        Ancylostoma caninum ova. Zoonotic (can be transmitted to humans).  Penetrate the and may cause cutaneous larva migrans in humans. <a href="https://www.cdc.gov/parasites/zoonotichookworm/biology.html" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>The CDC has additional information here.</a>
        </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Whipworms</span>
        <img src={trichuris} style={{borderRadius:"5%", maxWidth: "96%"}} alt="trichurus ova"/>
        Trichuris vulpis ova. Not considered zoonotic (little risk of transmission to humans).
        </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Coccidia</span>
        <img src={isospora} style={{borderRadius:"5%", maxWidth: "96%"}} alt="isospora ova"/>
        Isospora canis. Not zoonotic (cannot be transmitted to humans).
        </Paper>
        </Grid>

      </Grid>

      <h3><a href="https://capcvet.org/guidelines/general-guidelines/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Companion Animal Parasite Council General Guidelines</a></h3>

      <p style={{fontSize: ".8rem"}}><sup>1</sup>Companion Animal Parasite Council (CPAC). <a href="https://capcvet.org" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>capcvet.org</a>. 2020.</p>
      <p style={{fontSize: ".8rem"}}><sup>2</sup><a href="https://capcvet.org" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Dryden et al. Comparison of common fecal flotation techniques for the recovery of parasite eggs and oocysts. Vet Ther 2005.</a></p>

      </Typography>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
