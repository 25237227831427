import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
          <Grid container spacing={16}>
            <Grid item xs={12} md={6}>
            <Typography className={classes.contentBlock}>
            <span style={{fontWeight: "700"}}>Puppies:</span> 2 x &#123;I3/3 C1/1 PM3/3&#125; = 28
            </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography className={classes.contentBlock}>
            <span style={{fontWeight: "700"}}>Adult Dogs:</span> 2 x &#123;I3/3 C1/1 PM4/4 M2/3&#125; = 42
            </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography className={classes.contentBlock}>
            <span style={{fontWeight: "700"}}>Kittens:</span> 2 x &#123;I3/3 C1/1 PM3/2&#125; = 26
            </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
            <Typography className={classes.contentBlock}>
            <span style={{fontWeight: "700"}}>Adult Cats:</span> 2 x &#123;I3/3 C1/1 PM3/2 M1/1&#125; = 30
            </Typography>
            </Grid>
          </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
