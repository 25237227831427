import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import HipDysplasiaOA from '../images/hipDysplasia.jpg';
import DistractedHips from '../images/distractedHips.jpg';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (


      <div>
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Cranial cruciate ligament (CCL) injury is a common condition that causes knee instability and pain. It is by far the most common cause of hindlimb lameness in the dog. Without surgical treatment, CCL injury speeds the progression of osteoarthritis.  The ACL is the analogous structure in the human.  Two important differences between CCL disease and an ACL injury are that: 1. The dog's knee joint is always on a sloped angle, and 2. The human ACL has better blood supply and can be repaired with surgery when torn.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://petparenthandouts.com/ccl" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cranial Cruciate Ligament Injury Handout</a> from PetParentHandouts.com</h3>
      </Typography>
      <Typography>
      <h3><a href="https://www.acvs.org/small-animal/cranial-cruciate-ligament-disease" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cranial Cruciate Ligament Disease Handout</a> from the American College of Veterinary Surgeons</h3>
      </Typography>
      <Typography>
      <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952244" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Ruptured Cranial Cruciate Ligaments in Dogs</a> from the Veterinary Partner</h3>
      </Typography>
      </Grid>
      </Grid>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
