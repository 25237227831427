import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Typography gutterBottom>
        This is common in brachycephalic (short-nosed) dogs following trauma.
      </Typography>
      <Typography gutterBottom>
        More than the eye 'poping out,' it should be thought of as the eyelids becoming trapped behind the center of the eye.  The treatment involves pulling the eyelids back over the globe and surgically holding them in place until the swelling goes down.
      </Typography>
      <Typography gutterBottom>
        Thoroughly lubricate the eye with sterile ophthalmic lubricant until surgical correction can be performed under anesthesia.
      </Typography>
      <Typography>
      <h3><a href="http://www.acvo.org/common-conditions-1/2018/2/2/prosthetics" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Proptosis Handout</a> from the ACVO</h3>
      </Typography>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
