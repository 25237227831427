import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Typography gutterBottom>
      The Modified Glascow Coma Scale can be used hourly to monitor changes and help gauge the prognosis.<sup>1</sup>
    </Typography>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Modified Glascow Coma Scale</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.fullwidth}>
      <div>
      <Paper className={classes.tablePaper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="right">Score</TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell align="left">Motor Activity</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">Normal gait, normal spinal reflexes</TableCell>
            <TableCell align="right">6</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Hemiparesis, tetraparesis, or decerebrate activity</TableCell>
            <TableCell align="right">5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Recumbent, intermittent extensor rigidity</TableCell>
            <TableCell align="right">4</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Recumbent, constant extensor rigidity</TableCell>
            <TableCell align="right">3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Recumbent, constant extensor rigidity with opisthotonus</TableCell>
            <TableCell align="right">2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Recumbent, hypotonia of muscles, depressed or absent spinal reflexes</TableCell>
            <TableCell align="right">1</TableCell>
          </TableRow>
          <TableHead>
            <TableRow>
              <TableCell align="left">Brain Stem Reflexes</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell align="left">Normal pupillary light reflexes and oculocephalic reflexes</TableCell>
            <TableCell align="right">6</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Slow pupillary light reflexes and normal to reduced oculocephalic reflexes</TableCell>
            <TableCell align="right">5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Bilateral unresponsive miosis with normal to reduced oculocephalic reflexes</TableCell>
            <TableCell align="right">4</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Pinpoint pupils with reduced to absent oculocephalic reflexes</TableCell>
            <TableCell align="right">3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Unilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</TableCell>
            <TableCell align="right">2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Bilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes</TableCell>
            <TableCell align="right">1</TableCell>
          </TableRow>
          <TableHead>
            <TableRow>
              <TableCell align="left">Level of Consciousness</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableRow>
            <TableCell align="left">Occasional periods of alertness and responsive to environment</TableCell>
            <TableCell align="right">6</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Depression or delirium, capable of responding but response may be inappropriate</TableCell>
            <TableCell align="right">5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Semicomatose, responsive to visual stimuli</TableCell>
            <TableCell align="right">4</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Semicomatose, responsive to auditory stimuli</TableCell>
            <TableCell align="right">3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Semicomatose, responsive only to repeated noxious stimuli</TableCell>
            <TableCell align="right">2</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Comatose, unresponsive to repeated noxious stimuli</TableCell>
            <TableCell align="right">1</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
    <Typography style={{fontWeight: "700", paddingTop: "1rem", paddingBottom: "1rem"}}>
      ***Total the score from the 3 categories above***
    </Typography>
    <Paper className={classes.tablePaper}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="center">MGCS Score</TableCell>
          <TableCell align="right">Suggested Prognosis</TableCell>
        </TableRow>
      </TableHead>
      <TableRow>
        <TableCell align="center">3-8</TableCell>
        <TableCell align="right">Grave</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center">9-14</TableCell>
        <TableCell align="right">Gaurded</TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="center">15-18</TableCell>
        <TableCell align="right">Good</TableCell>
      </TableRow>
    </Table>
    </Paper>
    <Typography style={{fontSize: ".8rem", paddingTop: "1rem"}}>Tables from Platt et al. The Prognostic Value of the Modified Glasgow Coma Scale in Head Trauma in Dogs. J Vet Intern Med. 2001.</Typography>
    </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <Typography style={{fontWeight: "700"}} gutterBottom>
      Our goal is to minimize the secondary effects and maximize the chance of recovery.
    </Typography>
    <Typography gutterBottom>
      1. Hypertonic saline or mannitol may be given to draw fluid off out of the intracranial space and decrease intracranial pressure.  Mannitol may also decrease production of cerebral spinal fluid.
    </Typography>
    <Typography gutterBottom>
    2. Flow by oxygen may help maintain perfusion.
    </Typography>
    <Typography gutterBottom>
    3. The patient's body should be elevated with the head about 30 degrees above the hind end.
    </Typography>
    <Typography style={{fontSize: ".8rem"}}><sup>1</sup>Platt et al. The Prognostic Value of the Modified Glasgow Coma Scale in Head Trauma in Dogs. J Vet Intern Med. 2001.</Typography>

    </div>



  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
