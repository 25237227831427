import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import AdditionalResources from './subtopics/AdditionalResources';
import DietDCM from '../heart/subtopics/dietDCM';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class NutritionMainPage extends Component {
  componentWillMount() {
    document.title = "Nutrition";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Nutrition</h1>

            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Key Points for Communication</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <div className={classes.contentBlock}>
              <Typography style={{fontWeight: "700"}}>
              Avoid grain-free diets.
              </Typography>
              <Typography gutterBottom>
              Many grain-free diets have been linked to a form of heart disease called dilated cardiomyopathy (DCM). Approximately one-third of dogs with DCM die suddenly with no obvious signs of disease.
              </Typography>
              <Typography style={{fontWeight: "700"}}>
              Feed your pet high quality food appropriate for their life stage from a trusted pet food brand.
              </Typography>
              <Typography gutterBottom>
              An AAFCO Nutritional Adequacy Statement that indicates the food is complete and balanced.
              </Typography>
              <Typography style={{fontWeight: "700"}}>
              Monitor your pet's weight and body condition score (BCS) to ensure its food intake is appropriate.
              </Typography>
              <Typography gutterBottom>
              Obesity has been linked to several health problems in dogs and cats. The nutritional requirements of individuals vary with breed, activity level, and life stage.
              </Typography>
              </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Diet-Associated Dilated Cardiomyopathy</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
            <DietDCM/>
            </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Additional Resources</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <AdditionalResources/>
            </ExpansionPanelDetails>
            </ExpansionPanel>


      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

NutritionMainPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NutritionMainPage);
