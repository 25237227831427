import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

import AdditionalResources from './subtopics/AdditionalResources';
import Intervertebral from '../neuro/subtopics/IntervertebralDiscDisease';
import NeckPain from '../neuro/subtopics/NeckPain';
import Forelimb from './subtopics/Forelimb';
import Hindlimb from './subtopics/Hindlimb';





const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Muscles & Bones";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Muscles & Bones</h1>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Key Points</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                <Typography>
                  Signalment (age, sex, reproductive status, breed) is especially important for patients presenting with a limp.  Certain conditions are common to certain breeds or in certain age groups.
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography>
                  Cranial cruciate ligament disease is the most common cause of hindlimb lameness in the dog.  Once fractures and dislocations have been ruled out, cranial cruciate ligament disease is the most likely cause of the lameness.
                </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography>
                 With few exceptions, rest is a key component of medical or surgical therapy for many of the conditions.  Pets are prone to re-injury and do a terrible job keeping weight off the affected limb.  Strict kennel rest with only short leash walks for elimination (for dogs) should be emphasized.
                </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Forelimb</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Forelimb/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Hindlimb</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <Hindlimb/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Back and Neck</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
            <div>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.subheading}>Intervertebral Disc Disease (IVDD)</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.contentBlock}>
                  <Intervertebral/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.subheading}>Neck Pain</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.contentBlock}>
                  <NeckPain/>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Osteoarthritis</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Grid container spacing={4}>
              <Grid item xs={12}>
              <Typography gutterBottom>
                Canine osteoarthritis (OA), also termed degenerative joint disease (DJD), is a common condition in dogs that affects their joints and causes pain.
              </Typography>
              <Typography gutterBottom>
                Osteoarthritis is complex condition affecting dogs of all ages and can be primary or secondary.
              </Typography>
              </Grid>
              <Grid item xs={12}>
              <Typography>
              <h3><a href="https://petparenthandouts.com/osteoarthritis" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Osteoarthritis Handout</a> from PetParentHandouts.com</h3>
              </Typography>
              <Typography>
              <h3><a href="https://petparenthandouts.com/osteoarthritis-management" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Osteoarthritis Management Handout</a> from PetParentHandouts.com</h3>
              </Typography>
              </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Additional Resources</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <AdditionalResources/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
