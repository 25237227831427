import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import IVDDRadiograph from '../images/IVDD.jpg'


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (


      <div>
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Chondrodystrophic breeds (think short stubby legs), most commonly Dachsunds, suffer from this disease, but it can be seen in any dog.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        The clinical signs range from pain → loss of proprioception → loss of motor function → loss of superficial pain perception → loss of deep pain perception.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Pain may be as subtle as reluctance to go on the usual walk, reluctance to jump onto the couch, subtle lameness (limping), or holding up one forepaw.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        For mild injuries where medical management is elected, rest is the most important component of the treatment plan.  This means strict rest in a small confined space with no jumping, playing, or running off leash.  Although the acute inflammation may improve within days, it generally takes 3-6 weeks of strict rest as we wait for protruded disk material to fibrose.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://www.acvs.org/small-animal/intervertebral-disc-disease" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>IVDD Handout</a> from the American College of Veterinary Surgeons</h3>
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Intervertebral Disc Disease</span></div>
      <img src={IVDDRadiograph} style={{maxWidth: "96%"}}/>
      <div className={classes.left}>
      Notice the narrowing of the disc space at L2/L3.  The bridging underneath is termed "spondylosis deformans"&mdash;a common incidental finding that is NOT pathologic.
      </div>
      </Paper>
      </Grid>
      </Grid>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
