import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import BrokenTooth from '../images/brokenTooth.jpg'


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12}>
    <Typography gutterBottom>
      Broken teeth are painful for dogs and cats.  If the pulp cavity of the tooth is exposed, extraction or root canal therapy (less common) is necessary.  Even if the overt signs of pain have subsided, broken teeth will continue to cause pain.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography>
      Chewing on hard things often results in broken teeth.  Dogs love to chew; some over-do it.  Rocks, bones, antlers, hard plastic toys, and ice cubes can all result in broken teeth.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography>
    <h3><a href="http://www.toothvet.ca/PDFfiles/pulp_exposure.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Pulp Exposure in Dogs and Cats</a> <span style={{fontWeight: "500"}}>from Fraiser Hale, DVM, FAVD, DipAVDC</span></h3>
    </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Complicated Crown Fracture</span>
      <img src={BrokenTooth} style={{borderRadius:"8%", maxWidth: "96%"}}/>
      <div>This slab fracture is a complicated crown fracture (the root is exposed).  Also notice the gingival recession above the tooth.</div>
      </Paper>
    </Grid>
    </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
