import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import ToothRootAbscess from '../../dentistry/subtopics/ToothRootAbscess';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>


      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Allergic Reaction</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Typography>
          Facial swelling is one of several signs that can be seen with type 1 hypersensitivity reactions.  This can be the result of a vaccine, an insect bite, or a bee sting.  Emergency treatment depends on the severity of the reaction. Anaphylaxis can be a life-threatening systemic hypersensitivity reaction.  The appearance of the gall bladder wall can be evaluated with ultrasound and can help identify anaphylaxis.
        </Typography>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Juvenile Cellulitis (Puppy Strangles)</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
        <Typography gutterBottom>
          We're not entirely sure why this happens, but it has something to do with immune system dysfunction.  It may start as pimples on the muzzle, face, and ear pinna.  Secondary bacterial skin infection often follows. This condition should be differentiated from an allergic reaction.
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography>
        <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952835" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Strangles in Puppies Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
        </Typography>
        </Grid>
        </Grid>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Neoplasia (Cancer)</Typography>
      </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
            <Typography>
            Cancer can do whatever it wants to do.  Some nasal cancers or bone and cartilage cancers will cause facial swelling.  Even in older patients, facial swellings are more often tooth root abscesses.
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <h3><a href="https://petparenthandouts.com/cancer" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cancer in Dogs Handout</a> from PetParentHandouts.com</h3>
            </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Tooth Root Abscess</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <ToothRootAbscess/>
      </ExpansionPanelDetails>
      </ExpansionPanel>

    </div>



  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
