import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdditionalResources from './subtopics/AdditionalResources'

import EruptionTimes from './subtopics/EruptionTimes';
import DentalFormulas from './subtopics/DentalFormulas';
import ToothAnatomy from './subtopics/ToothAnatomy';
import Glossary from './subtopics/Glossary';
import ToothRootAbscess from './subtopics/ToothRootAbscess';
import BrokenTooth from './subtopics/BrokenTooth';
import DentalProphy from './subtopics/DentalProphy';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Dentistry";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Dentistry</h1>


          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Tooth Eruption & Approximate Age</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
              <EruptionTimes />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Dental Formulas</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <DentalFormulas />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Tooth Anatomy</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fullwidth}>
                <ToothAnatomy />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Dental Prophylaxis</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
            <DentalProphy/>
          </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Broken Tooth</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
            <BrokenTooth/>
          </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Tooth Root Abscess</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
            <ToothRootAbscess/>
          </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Glossary</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
            <Glossary/>
          </ExpansionPanelDetails>
          </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Additional Resources</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <AdditionalResources/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
