import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div className={classes.fullwidth}>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Normal Vitals</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <div>
                      <Paper className={classes.tablePaper}>

                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">Dogs</TableCell>
                            <TableCell align="center">Cats</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">Temperature (F)</TableCell>
                            <TableCell colSpan={2} align="center">100 - 102</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left">Heart Rate</TableCell>
                            <TableCell align="center">70 - 140</TableCell>
                            <TableCell align="center">140 - 180 <br/>(180 - 220 stressed)</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left">Respiratory Rate</TableCell>
                            <TableCell colSpan={2} align="center">16 - 30</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Mucous Membrane Color</TableCell>
                            <TableCell colSpan={2} align="center">pink</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Capillary Refill Time</TableCell>
                            <TableCell colSpan={2} align="center">1 - 2</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Systolic Blood Pressure</TableCell>
                            <TableCell align="center">100 - 160</TableCell>
                            <TableCell align="center">100 - 170</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </Paper>
                    <Typography className={classes.left} gutterBottom>
                      This table is meant as a rough guide and supplement to the knowledge and care of a veterinary team.  They are written with the veterinary nurse in mind, and serve only as a basic starting point for patient triage.  Never let these values delay veterinary treatment.
                    </Typography>
                    </div>



        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Sick or Not Sick (Critical Vitals)</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

        <div>
            <Paper className={classes.tablePaper}>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Dogs</TableCell>
                  <TableCell align="center">Cats</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">Temperature (F)</TableCell>
                  <TableCell align="center">&#60;99, &#62;105</TableCell>
                  <TableCell align="center">&#60;98, &#62;106</TableCell>

                </TableRow>

                <TableRow>
                  <TableCell align="left">Heart Rate</TableCell>
                  <TableCell align="center">&#60;60, &#62;160</TableCell>
                  <TableCell align="center">&#60;150, &#62;250</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left">Respiratory Rate</TableCell>
                  <TableCell colSpan={2} align="center">labored, rapid, shallow</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Mucous Membrane Color</TableCell>
                  <TableCell colSpan={2} align="center">white, grey, brick red, blue</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Capillary Refill Time</TableCell>
                  <TableCell colSpan={2} align="center">&#60;1, &#62;3</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Systolic Blood Pressure</TableCell>
                  <TableCell colSpan={2} align="center">&#60;90, &#62;180</TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Paper>
          <Typography className={classes.left} gutterBottom>
            This table is meant as a rough guide and supplement to the knowledge and care of a veterinary team.  They are written with the veterinary nurse in mind, and serve only as a basic starting point for patient triage.  Never let these values delay veterinary treatment.
          </Typography>
          </div>

        </ExpansionPanelDetails>
      </ExpansionPanel>

    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
