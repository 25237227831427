import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {SketchField, Tools} from 'react-sketch';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  divided: {
  margin: '20px 0px',
},
firstInput: {
  marginRight: '15px',
},
secondInput: {
  marginLeft: '15px',
}
});

const ranges = [
{
  value: '0',
  label: '0',
},
{
  value: '1',
  label: '1',
},
{
  value: '2',
  label: '2',
},
{
  value: '3',
  label: '3',
},
{
  value: '4',
  label: '4',
},
{
  value: '',
  label: '',
},
];

const proprioceptionValue = [
{
  value: '0',
  label: '0',
},
{
  value: '1',
  label: '1',
},
{
  value: '2',
  label: '2',
},
{
  value: '',
  label: '',
},
];

const sNystagValue = [
{
  value: 'up',
  label: 'up',
},
{
  value: 'down',
  label: 'down',
},
{
  value: 'left',
  label: 'left',
},
{
  value: 'right',
  label: 'right',
},
{
  value: 'rotary',
  label: 'rotary',
},
{
  value: '',
  label: '',
},
];

class EyeExamForm extends Component {

  state = {
    weight: '',
    KnucklingFrontL: '2',
    KnucklingFrontR: '2',
    KnucklingRearL: '2',
    KnucklingRearR: '2',
    HoppingFrontL: '2',
    HoppingRearL: '2',
    HoppingFrontR: '2',
    HoppingRearR: '2',
    ExtensorR: '2',
    ExtensorL: '2',
    WheelVR: '',
    WheelVL: '',
    WheelNVL: '',
    WheelNVR: '',
    VisionR: '2',
    VisionL: '2',
    PupilSizeR: '2',
    PupilSizeL: '2',
    PlrR: '2',
    PlrL: '2',
    PalpebralR: '2',
    PalpebralL: '2',
    StrabismusR: '2',
    StrabismusL: '2',
    PNystagL: '2',
    PNystagR: '2',
    SNystagR: '',
    SNystagL: '',
    MasticR: '2',
    MasticL: '2',
    SensationFaceR: '2',
    SensationFaceL: '2',
    PinnaeL: '2',
    PinnaeR: '2',
    Tongue: '2',
    FacialMuscleL: '2',
    FacialMuscleR: '2',
    Swallowing: '2',
    BicepsL: '',
    BicepsR: '',
    TricepsL: '',
    TricepsR: '',
    ExCarpiL: '',
    ExCarpiR: '',
    WithdrawFR: '2',
    WithdrawFL: '2',
    PatellarR: '2',
    PatellarL: '2',
    CrTibL: '',
    CrTibR: '',
    GastrocR: '',
    GastrocL: '',
    WithdrawRL: '2',
    WithdrawRR: '2',
    PerinealR: '2',
    PerinealL: '2',
    PanniculusR: '',
    PanniculusL: '',
    CrossExtenR: '0',
    CrossExtenL: '0',
    TailTone: '2',
    AnalTone: '2',

    multiline: 'Controlled',
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };




  componentWillMount() {
    document.title = "Neuro Exam";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.nintysix}>

        <h1>Neurologic Examination</h1>
        <Grid container justify="flex-start" className={classes.root}>

          <Grid item className={classes.left} xs={12}>
          <TextField
          id="date"
          type="date"
          defaultValue=""
          className={classes.textField}
          margin="normal"
          />
          </Grid>
          <Grid item className={classes.left} xs={12} sm={6}>
          <TextField
                   id="standard-textarea"
                   label="Number"
                   multiline
                   className={classes.textField}
                   margin="normal"
                 />
          </Grid>
          <Grid item className={classes.left} xs={12} sm={6}>


           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    className={classes.textField}
                    margin="normal"
                  />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Neurologic History (Onset, Duration, Progression)"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Current Medications & Previous Treatments"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              label="Weight"
              id="simple-start-adornment"
              className={classes.textField}
              InputProps={{
                endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Mental Status (Alert, Obtunded, Comatose)"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
              />
          </Grid>
          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Posture (Head Tilt, Tremors, Falling, Circling)"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
              />
          </Grid>
          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Gait (Paresis/Paralysis, Ataxia)"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
              />
          </Grid>
          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Motor (yes/no, left/right/bilateral)"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
              />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Able to Urinate"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
              />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Grid container justify='flex-start'>

              <Grid item xs={3} sm={3}>
                <h2>Left</h2>
              </Grid>
              <Grid item xs={6} md={4}>
                <h2 style={{fontWeight:'500', fontSize: '1.1rem'}}>Proprioception</h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2>Right</h2>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.KnucklingFrontL}
                onChange={this.handleChange('KnucklingFrontL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Knuckling (front)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.KnucklingFrontR}
                onChange={this.handleChange('KnucklingFrontR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.KnucklingRearL}
                onChange={this.handleChange('KnucklingRearL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Knuckling (rear)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.KnucklingRearR}
                onChange={this.handleChange('KnucklingRearR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.HoppingFrontL}
                onChange={this.handleChange('HoppingFrontL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Hopping (front)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.HoppingFrontR}
                onChange={this.handleChange('HoppingFrontR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.HoppingRearL}
                onChange={this.handleChange('HoppingRearL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Hopping (rear)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.HoppingRearR}
                onChange={this.handleChange('HoppingRearR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.ExtensorL}
                onChange={this.handleChange('ExtensorL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Extensor Postural Thrust
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.ExtensorR}
                onChange={this.handleChange('ExtensorR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WheelVL}
                onChange={this.handleChange('WheelVL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Wheelbarrowing (visual)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WheelVR}
                onChange={this.handleChange('WheelVR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WheelNVL}
                onChange={this.handleChange('WheelNVL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Wheelbarrowing (non-visual)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WheelNVR}
                onChange={this.handleChange('WheelNVR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>



            </Grid>
          </Grid>



          <Grid item xs={12} sm={6} lg={4}>
            <Grid container justify='flex-start'>
              <Grid item xs={3} sm={3}>
                <h2>Left</h2>
              </Grid>
              <Grid item xs={6} md={4}>
                <h2 style={{fontWeight:'500', fontSize: '1.1rem'}}>Cranial Nerves</h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2>Right</h2>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.VisionL}
                onChange={this.handleChange('VisionL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Vision & Menace (II)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.VisionR}
                onChange={this.handleChange('VisionR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PupilSizeL}
                onChange={this.handleChange('PupilSizeL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Pupil Size (II, III)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PupilSizeR}
                onChange={this.handleChange('PupilSizeR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PlrL}
                onChange={this.handleChange('PlrL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              PLR (II, III)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PlrR}
                onChange={this.handleChange('PlrR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PalpebralL}
                onChange={this.handleChange('PalpebralL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Palpebral (V, VII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PalpebralR}
                onChange={this.handleChange('PalpebralR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.StrabismusL}
                onChange={this.handleChange('StrabismusL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Strabismus (VIII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.StrabismusR}
                onChange={this.handleChange('StrabismusR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PNystagL}
                onChange={this.handleChange('PNystagL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Physiologic Nystagmus (VIII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PNystagR}
                onChange={this.handleChange('PNystagR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.SNystagL}
                onChange={this.handleChange('SNystagL')}
              >
                {sNystagValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Spontaneous Nystagmus (VIII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.SNystagR}
                onChange={this.handleChange('SNystagR')}
              >
                {sNystagValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.MasticL}
                onChange={this.handleChange('MasticL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Mastication (V)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.MasticR}
                onChange={this.handleChange('MasticR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.SensationFaceL}
                onChange={this.handleChange('SensationFaceL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Face Sensation (V)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.SensationFaceR}
                onChange={this.handleChange('SensationFaceR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PinnaeL}
                onChange={this.handleChange('PinnaeL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Pinnae Sensation (VII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PinnaeR}
                onChange={this.handleChange('PinnaeR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.FacialMuscleL}
                onChange={this.handleChange('FacialMuscleL')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Facial Muscles (VII)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.FacialMuscleR}
                onChange={this.handleChange('FacialMuscleR')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.Swallowing}
                onChange={this.handleChange('Swallowing')}
              >
                {proprioceptionValue.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Swallowing (IX, X)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              </Grid>


            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Grid container justify='flex-start'>

              <Grid item xs={3} sm={3}>
                <h2>Left</h2>
              </Grid>
              <Grid item xs={6} md={4}>
                <h2 style={{fontWeight:'500', fontSize: '1.1rem'}}>Spinal Reflexes</h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2>Right</h2>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.BicepsL}
                onChange={this.handleChange('BicepsL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Biceps (C6-C8)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.BicepsR}
                onChange={this.handleChange('BicepsR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.TricepsL}
                onChange={this.handleChange('TricepsL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Triceps (C7-T1)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.TricepsR}
                onChange={this.handleChange('TricepsR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.ExCarpiL}
                onChange={this.handleChange('ExCarpiL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Extensor Carpi (C7-T1)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.ExCarpiR}
                onChange={this.handleChange('ExCarpiR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WithdrawFL}
                onChange={this.handleChange('WithdrawFL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Forelimb Withdraw (C6-T2)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WithdrawFR}
                onChange={this.handleChange('WithdrawFR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PatellarL}
                onChange={this.handleChange('PatellarL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Patellar (L4-L6)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PatellarR}
                onChange={this.handleChange('PatellarR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CrTibL}
                onChange={this.handleChange('CrTibL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Cranial Tibial (L6-L7)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CrTibR}
                onChange={this.handleChange('CrTibR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>


              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.GastrocL}
                onChange={this.handleChange('GastrocL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Gastrocnemius (L6-S1)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.GastrocR}
                onChange={this.handleChange('GastrocR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WithdrawRL}
                onChange={this.handleChange('WithdrawRL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Hindlimb Withdraw (L5-S1)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.WithdrawRR}
                onChange={this.handleChange('WithdrawRR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PerinealL}
                onChange={this.handleChange('PerinealL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Perineal (S1-S2)
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.PerinealR}
                onChange={this.handleChange('PerinealR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Panniculus
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CrossExtenL}
                onChange={this.handleChange('CrossExtenL')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Cross Extensor
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CrossExtenR}
                onChange={this.handleChange('CrossExtenR')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>

              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.TailTone}
                onChange={this.handleChange('TailTone')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Tail Tone
              </Typography>
              </Grid>
              <Grid item xs={3}>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.AnalTone}
                onChange={this.handleChange('AnalTone')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Anal Tone
              </Typography>
              </Grid>
              <Grid item xs={3}>
              </Grid>


            </Grid>
          </Grid>



          <Grid item xs={12} sm={6} lg={4}>
            <Grid container justify='flex-start'  alignItems="flex-end">



              <Grid item xs={3} sm={3}>
                <h2 style={{fontWeight:'500', fontSize: '1.4rem'}}>Left</h2>
              </Grid>
              <Grid item xs={6} md={4}>
                <h2>Superficial Pain <br/><span style={{fontWeight:'500', fontSize: '0.8rem'}}>(Do NOT test if motor present)</span></h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2 style={{fontWeight:'500', fontSize: '1.4rem'}}>Right</h2>
              </Grid>


              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Forelimbs
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>

              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Hindlimbs
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>

              <Grid item xs={3} sm={3}>
                <h2 style={{fontWeight:'500', fontSize: '1.4rem'}}>Left</h2>
              </Grid>
              <Grid item xs={6} md={4}>
                <h2>Deep Pain <span style={{fontWeight:'500', fontSize: '0.8rem'}}>(Do NOT test if superficial pain present)</span></h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2 style={{fontWeight:'500', fontSize: '1.4rem'}}>Right</h2>
              </Grid>


              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Forelimbs
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>

              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={6} md={4}>
              <Typography>
              Hindlimbs
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>

              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divided}/>
              </Grid>

              <Grid container justify='flex-start'>

              <Grid item className={classes.left} xs={12}>
                <TextField
                  id="standard-full-width"
                  label="Hyperesthesia"
                  fullWidth
                  multiline
                  margin="normal"
                  className={classes.textField}
                />
              </Grid>
              <Grid item className={classes.left} xs={12}>
                <TextField
                  id="standard-full-width"
                  label="Muscle Atrophy"
                  fullWidth
                  multiline
                  margin="normal"
                  className={classes.textField}
                />
              </Grid>

              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divided}/>
              </Grid>

              <Grid container justify='flex-start'>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Muscle Tone
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
                startAdornment={<InputAdornment position="start">LF</InputAdornment>}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
                startAdornment={<InputAdornment position="start">RF</InputAdornment>}
              />
              </Grid>
              <Grid item md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>

              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
                startAdornment={<InputAdornment position="start">LR</InputAdornment>}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
                startAdornment={<InputAdornment position="start">RR</InputAdornment>}
              />
              </Grid>
              <Grid item md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Root Signature
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
                startAdornment={<InputAdornment position="start">LF</InputAdornment>}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
                startAdornment={<InputAdornment position="start">RF</InputAdornment>}
              />
              </Grid>
              <Grid item md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>

              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
                startAdornment={<InputAdornment position="start">LR</InputAdornment>}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
                startAdornment={<InputAdornment position="start">RR</InputAdornment>}
              />
              </Grid>
              <Grid item md={3}>
              </Grid>

            </Grid>
          </Grid>


          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Neuroanatomical Localization"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Differential Diagnoses"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Diagnostic Plan"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

        </Grid>
        </div>
      </div>

      );
  }
}

EyeExamForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EyeExamForm);
