import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AdditionalResources from './subtopics/AdditionalResources'
import ASA from './subtopics/ASAPhysicalStatus';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Anesthesia";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Anesthesia</h1>
            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Key Points for Communication</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.contentBlock}>
              <Typography style={{fontWeight: "700"}}>
              Pre-anesthetic Tests
              </Typography>
              <Typography gutterBottom>
              In addition to a physical examination, pre-anesthetic workup may include blood work, urinalysis, ECG (measuring the electrical activity of the heart), genetic tests for drug sensitivities, and testing for bleeding disorders.  The individual plan is tailored to the individual patient, and these tests help reduce the risks associated with anesthesia.
              </Typography>
              <Typography style={{fontWeight: "700"}}>
              Fasting (withholding food):
              </Typography>
              <Typography gutterBottom>
              For most planned anesthetic events, it is beneficial to withhold food for 6-12 hours before the event.  This is to reduce the risk of aspiration pneumonia (food or regurgitated material going down the airway).  This is often associated with nausea and vomiting due to the side-effects of anesthetic drugs.  New drugs (maropitant, Cerenia) can decrease nausea, reduce the risk of aspiration pneumonia, and have other benefits.  In most cases, water should NOT be withheld.  We want our patients well hydrated for surgery.  Animals with certain conditions and very young animals should not be fasted.
              </Typography>
              <Typography style={{fontWeight: "700"}}>
              Risk of Death
              </Typography>
              <Typography gutterBottom>
              The risks associated with anesthesia are lowered by careful planning and good monitoring, but serious risks still exist.  About 1 in 1000 cats and 1 in 2000 dogs that undergo anesthesia may have complications that lead to death.<sup>1</sup>
              </Typography>
              <Typography gutterBottom>
                <a href="https://petparenthandouts.com/anesthesia" target="_blank" rel="noopener noreferrer"  className={classes.contentLink}><h3>Pet Parent Handouts: Canine Anesthesia</h3></a>
              </Typography>
              <Typography style={{fontSize: ".8rem"}}><sup>1</sup>Matthews et al. Factors associated with anesthetic-related death in dogs and cats in primary care veterinary hospitals. JAVMA. Mar 2017.</Typography>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ASA/>

          <ExpansionPanel>

          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Endotracheal Tube Sizes</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>

          <div className={classes.fullwidth}>

      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Dogs</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
      <Grid container spacing={16}>
      <Grid item xs={12}>
        <div className={classes.fullwidth}>
          <Paper className={classes.tablePaper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Weight (kg)</TableCell>
                <TableCell align="left">Size (mmid)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">2</TableCell>
                <TableCell align="left">4.0 - 5.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">4</TableCell>
                <TableCell align="left">6.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">7</TableCell>
                <TableCell align="left">7.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">11</TableCell>
                <TableCell align="left">7.5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">13</TableCell>
                <TableCell align="left">8.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">15</TableCell>
                <TableCell align="left">8.5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">18</TableCell>
                <TableCell align="left">9.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">20</TableCell>
                <TableCell align="left">9.5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">22</TableCell>
                <TableCell align="left">10.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">27</TableCell>
                <TableCell align="left">11.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">31</TableCell>
                <TableCell align="left">12.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">36</TableCell>
                <TableCell align="left">14.0</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">50 +</TableCell>
                <TableCell align="left">16.0 - 18.0</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </Paper>
        </div>
        </Grid>
        <Grid item xs={12}>
        <Typography className={classes.contentBlock}>
        <p>Palpate the patient's trachea or compare the distal end of the ET tube to the width of the patient's nasal septum.</p>
        <p>Length can be determined by measuring from the nose to the point of the shoulder (thoracic inlet).</p>
        <p>Go down in size for brachycephalic breeds</p>
        </Typography>
        </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>


    <ExpansionPanel>
  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
    <Typography className={classes.subheading}>Cats</Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
  <Grid container spacing={16}>
  <Grid item xs={12}>
  <div className={classes.fullwidth}>
    <Paper className={classes.tablePaper}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="left">Weight (kg)</TableCell>
          <TableCell align="left">Size (mmid)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left">1</TableCell>
          <TableCell align="left">3.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">2</TableCell>
          <TableCell align="left">3.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">3</TableCell>
          <TableCell align="left">4.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">4</TableCell>
          <TableCell align="left">4.5</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">5</TableCell>
          <TableCell align="left">5.0</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </Paper>
  </div>
  </Grid>
  <Grid item xs={12}>
  <Typography className={classes.contentBlock}>
  <p>Palpate the patient's trachea or compare the distal end of the ET tube to the width of the patient's nasal septum.</p>
  <p>Length can be determined by measuring from the nose to the point of the shoulder (thoracic inlet).</p>
  </Typography>
  </Grid>
  </Grid>
  </ExpansionPanelDetails>
</ExpansionPanel>

    </div>

    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Phases of Anesthesia</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Pre-anesthesia:</span> This phase includes the initial history, physical examination, laboratory tests.  Any medical problems should be addressed.  About 15 minutes before induction, pre-anesthetic drugs are given to reduce anxiety, sedate the patient, and provide additional pain relief. These drugs also reduce the amount of anesthetic drugs needed, which helps minimize side effects and improves the recovery phase.
      </Grid>
      <Grid item xs={12} md={6}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Induction: </span> This phase includes intubation, where an endotracheal tube is placed to protect the airway and allow the use of anesthetic gases.  At induction, injectable drugs (often propofol or alfaxalone) or inhalants are used until the patient is relaxed enough to allow intubation.  These drugs should be given slowly to effect.  Secure the endotracheal tube in place by tying it around the jaw or behind the ears.  <span style={{fontWeight: "700"}}>The cuff should be inflated only to the point that anesthetic gas does not escape around it (listen at the mouth).  Overinflating the cuff on the endotracheal tube will put more pressure on the fragile cells that line the trachea and will lead to additional tracheitis (inflammation of the trachea) that may persist after anesthesia.  Always listen to ensure both lungs have good lung sounds when the patient is given a breath.  Over advancing the endotracheal tube can lead to ventilation of only one lung.</span> Many of the monitoring devices can be hooked up at this phase.
      </Grid>
      <Grid item xs={12} md={6}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Maintenance: </span> During this phase, inhalants (anesthetic gas) or injectable drugs (as in Total Intravenous Anesthesia or TIVA) are used to keep the patient unconscious.  Monitoring is key.  A table of the Minimum Alveolar Concentration (MAC) for several anesthetic gasses has been included below.  This concentration, at sea level, prevents 50% of patients from making concious movement.  <span style={{fontWeight: "700"}}>Many factors will influence the required concentration in an individual patient, so careful monitoring and frequent reassessment are key.</span>
        <Paper className={classes.tablePaper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Inhalant</TableCell>
              <TableCell align="left">MAC</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">Halothane</TableCell>
              <TableCell align="left">0.87</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Isoflurane</TableCell>
              <TableCell align="left">1.3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Sevoflurane</TableCell>
              <TableCell align="left">2.34</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">Desflurane</TableCell>
              <TableCell align="left">7.2</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Recovery: </span> During this phase, the patient returns to consciousness.  Continued monitoring is key to a successful recovery.  Patients may suddenly show signs of pain or dysphoria.  Dysphoric patients may vocalize and thrash.  Many of the drugs that treat pain will not treat dysphoria.  Tranquilizers or sedatives may be needed if the patient is dysphoric.  Patients may be affected by the anesthetic drugs for 12 or more hours after recovery.  They should be confined to a safe space with supervision to prevent self-injury.
      </Grid>
    </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel>


  <ExpansionPanel>
  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
    <Typography className={classes.heading}>Monitoring</Typography>
  </ExpansionPanelSummary>
  <ExpansionPanelDetails>
    <Typography className={classes.contentBlock}>
    <p><span style={{fontWeight: "700"}}>Circulation</span></p>
    <p>
      Peripheral pulse (rate, rhythm, quality), mucous membrane color, capillary refill time, auscultation of heart beat, pulse oximetry (% hemoglobin saturation), ECG for detection of arrhythmias, blood pressure (indirect or direct)
    </p>
    <p>EtCO<sub>2</sub>: 35 - 40 mmHg (28 - 35 for brain cases)</p>
    <p>BP: SAP > 90 mmHg, MAP 60 - 80 mmHg, DAP > 40 mmHg</p>

    <p><span style={{fontWeight: "700"}}>Oxygenation</span></p>
    <p>
      Pulse oximetry as estimation of hemoglobin saturation, arterial blood gas analysis for PaO<sub>2</sub>
    </p>
    <p>SpO<sub>2</sub>: > 95% on room air and > 98% on oxygen</p>
    <p>Oxygen consumption is usually 4 - 7 mL/kg/min, but maintenance flow = 30 mL/kg/min for semi-closed circle systems; maintenance flow is often doubled during the first 15 minutes of anesthesia (Induction Flow)</p>
    <p>For non-rebreathing systems (e.g. Bain Coaxial Breathing System), oxygen flow is 200 - 600 mL/kg/min or 1.5 to 3 x minute volume (MV = TV x RR); high end ensures washout of CO<sub>2</sub>, but cools patient and increases inhalant waste</p>
    <p><span style={{fontWeight: "700"}}>Ventilation</span></p>
    <p>
      Thoracic wall movement or breathing bag movement, auscultation of breath sounds, capnography for end-expired CO<sub>2</sub> measurement, arterial blood gas analysis for PaCO<sub>2</sub>, tidal volume (respirometry)
    </p>
    <p>Tidal volume is 10 - 15 mL/kg for most species</p>

    <p><span style={{fontWeight: "700"}}>Temperature</span></p>
    <p>
      Rectal or esophageal temperature monitoring
    </p>
    <p>During recovery, continue to monitor until temperature &ge; 98 <sup>o</sup>F</p>
    </Typography>
  </ExpansionPanelDetails>
</ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Additional Resources</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <AdditionalResources/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
