import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ekg from '../images/ekg.jpg'
import echo from '../images/echo.gif'
import TRRight from '../images/ThoraxR.jpg'
import TRVD from '../images/ThoraxVD.jpg'


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {


  render() {
    const { classes } = this.props;
    return (

      <div className={classes.fullwidth}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>Murmurs (and Auscultation)</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Grid container spacing={16}>
            <Grid item xs={12}>
            <Typography className={classes.contentBlock}>
            <p>A murmur is often heard when there is turbulent blood flow. This often happens if a leaky heart valve lets some blood flow backwards&mdash;against the normal current&mdash;because it doesn't quite close like it should.  Murmurs are graded, one through six, using the criteria listed in the table below.</p>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <div className={classes.fullwidth}>
            <Paper className={classes.tablePaper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Grade</TableCell>
                  <TableCell align="left">Criteria</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">I</TableCell>
                  <TableCell align="left">only heard by experienced listener</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">II</TableCell>
                  <TableCell align="left">soft murmer heard only in one valve location</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">III</TableCell>
                  <TableCell align="left">easily heard; radiates but has identifiable PMI (point of maximum intensity)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">IV</TableCell>
                  <TableCell align="left">easily heard anywhere on thorax; without palpable thrill; difficult to locate the PMI</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">V</TableCell>
                  <TableCell align="left">loud murmur anywhere in chest with palpable thrill (you can feel the vibration)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">VI</TableCell>
                  <TableCell align="left">very loud murmur with thrill; can be heard without touching stethoscope to chest wall</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
          </div>
          </Grid>
          <Grid item xs={12}>
          <Typography className={classes.contentBlock}>
          <p><span style={{fontWeight: "700"}}>Pitch:</span> high, low, musical, rumbling, whooping</p>
          <p><span style={{fontWeight: "700"}}>Quality:</span> plateau, crescendo ("ejection murmurs" as with SAS or PS), decrescendo, crescendo-decrescendo</p>
          <p>Innocent murmurs in puppies and kittens should: 1. be soft (&le; grade 3); 2. resolve by 16 wks age; 3. get softer over time</p>
          <p><span style={{fontWeight: "700"}}>Mitral valve regurgitation</span> is extremely common (especially in small breed dogs and cats). The grade of murmur does not correspond to the severity of disease. An echocardiogram or thoracic radiographs are needed to look for structural changes to the heart. Mitral regurgitation can be caused by endocardiosis, endocarditis, volume overload, or valve dysplasia.</p>
          <p><span style={{fontWeight: "700"}}>Left heart base murmurs</span> may be subaortic stenosis (SAS) or pulmonic stenosis (PS). The grade correlates better to severity of disease</p>
          <p>A Ventricular Septal Defect (VSD) often produces a <span style={{fontWeight: "700"}}>loud basilar systolic murmur</span>.  Small defects create loud murmurs which have better prognosis than large defects (which often produce soft murmurs).</p>
          <p>Atrioventricular (AV) valve prolapse causes <span style={{fontWeight: "700"}}>systolic clicks</span>.  Clicks are louder and more easily heard than gallops. Clicks are often only appreciated in early stages of valve disease as the valves prolapses and then snaps to its normal position.</p>
          <p><span style={{fontWeight: "700"}}>Gallops</span> are s3 and s4 heart sounds.  These are not normal in dogs and cats. s3 is at beginning of diastole (blood hits eccentrically dilated ventricle. s3 may be heart with Dilated Cardiomyopathy (DCM) or severe in cases of severe Patent Ductus Arteriosus (PDA). s4 is heard when atria contract.  It may be heard if the left ventricle cannot relax, or in cases of hypertrophic cardiomyopathy (HCM).  Unlike systolic clicks, gallops are generally associated with bad heart disease.</p>
          <p><span style={{fontWeight: "700"}}>Right systolic murmurs:</span> Think Tricuspid Valve Dysplasia (TVD) if the patient is young (Labs are prone to TVD). Right systolic murmurs may also be associated with a Ventricular Septal Defect (VSD).  If heard in an older dog, think endocardiosis</p>
          </Typography>
          </Grid>
          </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>EKG</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
            <Typography className={classes.contentBlock}>
            <p>An electrocardiograph (ECG, EKG) measures the electrical activity of the heart muscle.  It is this electrical activity that causes the heart to beat.</p>
            <p>Ideally, the patient should be in right lateral recumbency.  "White on Right; Smoke over Fire," can be used to remember lead placement.  The white lead ("White") goes on the right forelimb, often on the right axillary skin fold flap (near the armpit).  The red lead ("Fire") is placed on the left flank fold (that flap of skin just in front of the leg).  The black lead ("Smoke") goes on the left forelimb in the same fashion as the white lead.  If the patient is trembling, moving the leads distally (down the legs) can help reduce motion artifact.</p>
            </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>EKG</span></div>
              <img src={ekg} style={{borderRadius:"3%", maxWidth: "96%"}}/>
              <div>This is from a six lead EKG.  Most single lead traces are of lead II</div>
              </Paper>
            </Grid>
          </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>Thoracic Radiographs (Chest x-ray images)</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
              X-ray images of the heart can be used to measure heart size relative to vertebrae (vertebral heart score, VHS) and look for evidence of chamber enlargement.  With valvular heart disease, the heart gets bigger to make up for loss of function.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Right Lateral Chest Radiograph</span>
              <img src={TRRight} style={{borderRadius:"3%", maxWidth: "96%"}}/>
              <div>With the right side down, try to make sure the heads of the ribs on both sides are overlapping&mdash;if the thorax is rotated, scoring the heart will be less accurate.</div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper style={{textAlign: "center", margin: "auto"}}>
              <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Ventrodorsal Chest Radiograph</span>
              <img src={TRVD} style={{borderRadius:"3%", maxWidth: "96%"}}/>
              <div>Make sure the spinous processes are lined up over the vertebral bodies.</div>
              </Paper>
            </Grid>
          </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>Blood Pressure</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
          <Typography>
          Blood pressure can be taken by a blood pressure machine or Doppler ultrasound.  Systolic blood pressure in a calm pet should be below 160 mmHg.  It may approach 170 mmHg in normo-tensive cats in hospital.  Multiple readings higher than this in a relatively calm pet suggests hypertension.  This is usually the first test performed to limit stress-induced hypertension.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>Echocardiography</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography>
                Echocardiography uses sound waves (ultrasound) to look at images of the heart.  This can evaluate heart structure and function and is the gold standard for evaluation of many heart conditions.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper style={{textAlign: "center", margin: "auto"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Echocariogram</span>
                <img src={echo} style={{borderRadius:"3%", maxWidth: "96%"}}/>
                <div>Sound waves are used to create images of the heart in motion.</div>
                </Paper>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.subheading}>Biomarkers</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.contentBlock}>
          <div>
          <Typography gutterBottom><span style={{fontWeight: "700"}}>Troponin I</span> is an injury marker released from cardiac myocytes following death.</Typography>
          <Typography><span style={{fontWeight: "700"}}>NTproBNP</span> is a hormone released mostly from the left ventricle in response to wall stress and stretch&mdash;it is an appropriate test for cats with suspected left-sided failure that decline an echo.  The quantitative test must be sent to the lab and can help track progression of heart disease.  The SNAP (patient-side) test is useful for ruling out heart disease before treating suspected respiratory disease.</Typography>
          </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

      </div>

  );
}
}

DogFieldMedicine.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
