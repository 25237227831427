import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ulcerSimple from '../images/UlcerSimple.jpg';
import indolentUlcer from '../images/indolentUlcer.jpg';
import cornealPerforation from '../images/cornealPerforation.jpg';
import sequestrum from '../images/sequestrum.jpg';
import descemetocele from '../images/descemetocele.jpg';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Superficial Corneal Ulcer</span></div>
        <img src={ulcerSimple} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
      <Typography gutterBottom>
        A corneal ulcer involves damage to the surface of the eye. Rubbing at the eye, blinking, squinting, or tearing may be reported. Corneal ulcers are extremely painful. Mainstays of treatment include a hard plastic cone (to prevent further self-trauma to the eye), pain medications, and topical ophthalmic antibiotics to prevent infection.  Although most corneal ulcers heal within 7 days with proper treatment, these pets should have recheck examinations to ensure progress and resolution without complications.
      </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Indolent Ulcer</span></div>
        <img src={indolentUlcer} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>In the case of an indolent ulcer, loose flaps of tissue at the surface of the eye prevent normal healing. This type of ulcer is seen more commonly in older dogs and Boxers.</div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Descemetocele</span></div>
        <img src={descemetocele} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>When an ulcer is very deep, it reaches Descemet membrane, a thin bottom layer that does not take up stain.  Notice that only the ring of corneal stroma (and tear film) take up stain.</div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Sequestrum</span></div>
        <img src={sequestrum} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>This is a common complication in cats.  The brown tissue is dead.  Sometimes it will fall off on its own.  Sometimes it will need to be surgically removed.  Sometimes it results in a deep defect and a fragile eye. <a href="http://www.acvo.org/common-conditions-1/2018/2/23/corneal-sequestrum" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Sequestrum Handout</a> from ACVO</div>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Corneal Perforation</span></div>
        <img src={cornealPerforation} style={{borderRadius:"3%", maxWidth: "96%"}}/>
        <div>Notice the small central defect in the cornea.  The fluorescein stain is being pushed out by the fluid from the anterior chamber leaking out of a small puncture.  This is called the 'Seidel Test.'</div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://petparenthandouts.com/corneal-ulcers" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Corneal Ulcer Handout</a> from PetParentHandouts.com</h3>
      </Typography>
      <Typography>
      <h3><a href="http://www.acvo.org/common-conditions-1/2018/2/2/corneal-ulcer" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Corneal Ulcer Handout</a> from ACVO</h3>
      </Typography>
      </Grid>
      </Grid>

      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
