import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Intervertebral from './IntervertebralDiscDisease';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (


      <div>
      <Grid container spacing={4}>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Neck pain is common in dogs and is usually related to underlying intervertebral disk disease (IVDD).  There is more room in the cervical (neck region) spinal canal, so when a bit of disk material pushes on the spinal cord here, these dogs are often painful, but do not show proprioceptive deficits.  These dogs may have tense muscles in the neck, show forelimb lameness, hold their neck and head in an odd position, and are sometimes painful enough to lose their appetite.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Like intervertebral disk disease affecting the thoracic or lumbar regions, clinical signs range from pain → loss of proprioception → loss of motor function → loss of superficial pain perception → loss of deep pain perception.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        Pain may be as subtle as reluctance to go on the usual walk, reluctance to jump onto the couch, subtle lameness (limping), or holding up one forepaw.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography gutterBottom>
        For mild injuries where medical management is elected, rest is the most important component of the treatment plan.  This means strict rest in a small confined space with no jumping, playing, or running off leash.  Although the acute inflammation may improve within days, it generally takes 3-6 weeks of strict rest as we wait for protruded disk material to fibrose.
      </Typography>
      </Grid>
      <Grid item xs={12}>
      <Typography>
      <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952768" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Cervical (Neck) Disk Disease Handout</a> from Veterinary Partner</h3>
      </Typography>
      </Grid>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Intervertebral Disc Disease</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Intervertebral/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <Grid item xs={12}>
      </Grid>
      </Grid>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
