import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {

  render() {
    const { classes } = this.props;
    return (

      <div>
      <Typography className={classes.left} gutterBottom>
        <a href="http://eclinpath.com/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>eClinPath</h3></a>
        <span>Cornell University's eTextbook for clinical pathology</span>
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <a href="https://capcvet.org/guidelines/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>Companion Animal Parasite Council</h3></a>
        <span>Guidelines for parasitic infection</span>
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <a href="https://www.heartwormtoolkit.com/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>Heartworm Toolkit</h3></a>
        <span>From the American Heartworm Society</span>
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <a href="https://onlinelibrary.wiley.com/journal/1939165x#pane-01cbe741-499a-4611-874e-1061f1f4679e11" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>Veterinary Clinical Pathology</h3></a>
        <span>Journal of the American Society for Veterinary Clinical Pathology</span>
      </Typography>
      <Typography className={classes.left} gutterBottom>
        <a href="https://peer.tamu.edu//vetschool.asp" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>Histology examples from Dr. Larry Johnson</h3></a>
        <span>Includes many blood cytology samples with WBC identification</span>
      </Typography>
      </div>

  );
}
}

DogFieldMedicine.propTypes = {
classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
