import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Mct1 from '../images/mastCellTumor.jpg'
import Mct2 from '../images/Mct2.jpg'
import Mct3 from '../images/Mct4.jpg'



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={4}>
    <Grid item xs={12}>
    <Typography>
      Mast cell tumors are common skin tumors of dogs.  They are often locally aggressive, grow and shrink, get red or "angry", ulcerate and bleed, or cause local tissue swelling.  They send out inflammatory signals that may make dogs feel unwell or lose their appetite.  Surgical removal and histopathology are necessary to "grade" mast cell tumors and understand how they might behave in the individual patient.  For low grade tumors, surgery may be curative.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography>
    <h3><a href="https://www.acvs.org/small-animal/mast-cell-tumors" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Mast Cell Tumors Handout</a> <span style={{fontWeight: "500"}}>from the ACVS</span></h3>
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography>
    <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=4952018" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Mast Cell Tumors Handout</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
    </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
      <img src={Mct1} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
      <div>Mast cells are round cells with granules.</div>
      </Paper>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
      <img src={Mct2} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
      <div>Not all mast cell granules stain this well.</div>
      </Paper>
    </Grid>
    <Grid item xs={12} md={4}>
      <Paper style={{textAlign: "center", margin: "auto"}}>
      <div><span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Mast Cells</span></div>
      <img src={Mct3} style={{borderRadius:"50%", maxWidth: "96%"}} alt="mast cells"/>
      </Paper>
    </Grid>
    </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
