import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import awakeTeeth from '../images/awakeTeeth.jpg';
import afd from '../images/afd.jpg';
import probing from '../images/probing.gif';

import dentalRad from '../images/dentalRad.jpg';
import scaling from '../images/scaling.gif';
import polish from '../images/polish.gif';


import brushing from '../images/brushing.gif';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <div>
          <span style={{fontWeight: "700", fontSize: "1.2rem"}}>
          Important points for Communication:
          </span>
          </div>
          <div>
          <span style={{fontWeight: "700"}}>
          Pets with veterinary dental care live longer.</span>  A 2019 study on dog lifespan showed that the risk of death decreases as the frequency of veterinary dental scaling increases.<sup>1</sup>  Most dogs and cats would benefit from prophylactic dental cleaning at least yearly.  As bacteria tracts into the gingival sulcus, it can spread via the bloodstream and affect the heart, kidneys, and other organs.
          </div>
          <div>
          <span style={{fontWeight: "700"}}>
          The risk of anesthetic-related death is really low</span>&mdash;current reports indicate 11/10,000 for cats and 5/10,000 for dogs, and these reports include emergency procedures.<sup>2</sup>  Dental cleanings are elective procedures, and care can be taken to reduce the anesthetic risk.  Address the pet parent's concerns about anesthesia and communicate the benefits of dental care.
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>The mouth of the awake patient can often be examined and may help gauge the level of periodontal disease that is expected during the dental procedure.</span>
          <img src={awakeTeeth} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>

          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>The patient is anesthetized. </span> <a href="https://afd.avdc.org/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>The American Veterinary Dental College warns against Anesthesia Free Dentistry.
          <img src={afd} style={{borderRadius:"8%", maxWidth: "96%"}}/></a>

        </Paper>

        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontSize: "1.2rem"}}>Intraoral radiographs are taken to evaluated the entire mouth and all of the teeth.</span>
        <img src={dentalRad} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div>
        Much of the periodontal disease hides beneath the gum line.  X-ray images paired with periodontal probing and a thorough oral exam are the best way to identify periodontal disease.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem"}}>The calculus is removed from the teeth.</span> Tartar removal forceps or extraction forceps can be used to break free large bits of calculus.  An ultrasonic scaler is commonly used for most of the work.  A curette can be used under the gum line to carefully remove hidden plaque and calculus.
        <img src={scaling} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div>
        An ultrasonic scaler can be used to speed calculus removal.  This and similar handheld curettes should be held with their blade almost parallel to the surface of the crown to avoid scratching the crown as you scale.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem"}}>The crowns of all teeth are polished using a low-speed handpiece.</span> This reduces the small abrasions that were caused by scaling the teeth.
        <img src={polish} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div>
        Careful not to spend too much time in one place.  This tool seems gentle, but can quickly damage teeth and periodontal structures.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontWeight: "700", fontSize: "1.6rem"}}>6.</span> <span style={{fontSize: "1.2rem"}}>Periodontal probing</span>
        <img src={probing} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div>
        Explore around and between all teeth. The depth of the gingival sulcus should not be deeper than 2-3mm in dogs; it should not be deeper than 1mm in cats.<sup>3</sup>
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <div>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>7.</span> <span style={{fontWeight: "700", fontSize: "1.2rem"}}>Periodontal treatments and extractions</span> should be performed based on exam findings.  Some dogs will benefit from staging the dental procedure.  Two shorter anesthetic events are safer than one single longer event that tries to address all the problems at once.
          </div>
          <div>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>8.</span> <span style={{fontWeight: "700", fontSize: "1.2rem"}}>A dental sealant</span>, such as Sanos, should be applied to smooth the tooth surface.  This can protect against plaque formation and help prevent periodontal disease.<sup>4</sup>
          <h3><a href="http://www.allaccem.com/wp-content/upload/2013/02/SANOS-Client-Ed-Brochure-2013.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Sanos Client Brochure</a> <span style={{fontWeight: "500"}}>from AllAccem</span></h3>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>At Home Oral Care</span>
        <img src={brushing} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div>
        Daily tooth brushing is the best way to prevent tartar accumulation and gingivitis between dental cleanings.  <a href="https://www.amazon.com/Virbac-CET201-Enzymatic-Toothpaste-Dogs/dp/B074P77LB2/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Virbac CET toothpaste</a> is intended for pets.  It can be applied to the outside of the teeth with a gauze square or small makeup-remover pad.  This may be tolerated better than a toothbrush or finger brush.  Just gently apply the toothpaste with a couple swipes; the teeth do not need aggressive scrubbing.  <span style={{fontWeight: "700"}}>Daily tooth brushing is the single most effective method to reduce plaque accumulation&mdash;it's more than 3x as effective as dental chews.</span><sup>5</sup>
        </div>
        </Paper>
        </Grid>
      </Grid>
      <Typography style={{fontSize: ".8rem"}}><sup>1</sup><a href="https://jaaha.org/doi/10.5326/JAAHA-MS-6763?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%3dpubmed" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Silvan et al. Risk Factors Associated with Lifespan in Pet Dogs Evaluated in Primary Care Veterinary Hospitals. JAAHA. 2019.</a></Typography>
      <Typography style={{fontSize: ".8rem"}}><sup>2</sup><a href="https://avmajournals.avma.org/doi/abs/10.2460/javma.250.6.655" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Matthews et al. Factors associated with anesthetic-related death in dogs and cats in primary care veterinary hospitals. JAVMA. 2017</a></Typography>
      <Typography style={{fontSize: ".8rem"}}><sup>3</sup>Bellows et al. 2019 AAHA Dental Care Guidelines for Dogs and Cats. J Am Anim Hosp Assoc. 2019.</Typography>
      <Typography style={{fontSize: ".8rem"}}><sup>4</sup><a href="https://www.ncbi.nlm.nih.gov/pubmed/24371922" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Sitzman C. Evaluation of a hydrophilic gingival dental sealant in beagle dogs. J Vet Dent. 2013.</a></Typography>
      <Typography style={{fontSize: ".8rem"}}><sup>5</sup><a href="https://onlinelibrary.wiley.com/doi/full/10.1111/jsap.12964" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Allan et al. Prospective randomised blinded clinical trial assessing effectiveness of three dental plaque control methods in dogs. J Sm Animal Practice. April 2019.</a></Typography>

      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
