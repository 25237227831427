import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import righting from '../images/righting.gif';
import extensor from '../images/extensor.gif';
import hopping from '../images/hopping.gif';
import walking from '../images/walking.gif';
import withdraw from '../images/withdraw.gif';
import patellar from '../images/patellar.gif';



const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Button component={Link} to="/neuro-form" variant="outlined" color="" className={classes.button} target="_blank" rel="noopener noreferrer">
        Neuro Exam Form
        </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>1.</span> <span style={{fontSize: "1.2rem"}}>Observe the patient.</span>  What is the patient's <span style={{fontWeight: "700", fontSize: "1.2rem"}}>mental status</span>?  Is the patient alert and engaged, dull, or comatose?  How about the patient's <span style={{fontWeight: "700", fontSize: "1.2rem"}}>posture</span>?  Dose the patient seem hunched, have a head tilt, or falls over.  Is the patient ambulatory (can the patient more than 5 steps without falling)? A <span style={{fontWeight: "700", fontSize: "1.2rem"}}>gait evaluation</span> may be performed.  Assessing the patient's <span style={{fontWeight: "700", fontSize: "1.2rem"}}>ability to urinate</span> is important for many IVDD and spinal trauma cases.
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Gait evaluation</span>
        <img src={walking} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        Observe the patient as they walk away and then towards you.  Solid concrete footing is often best.  Listen for any scuffing of the nails, and look for abnormal nail wear.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>2.</span> <span style={{fontSize: "1.2rem"}}>Cranial nerves may be assessed.</span> This may be done with specific nerve function tests such as the menace response, palpebral reflex, pupillary light reflex (PLR), and other cranial nerve tests.  In general, if the patient's eyes are central with normal symmetrical pupils, there is a bilateral menace response, the patient responds to voice, palpebral and lip simulation, and there is no gagging, trouble eating, or excessive drooling reported, then CNII through IX and XII are most likely in good working order.
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>3.</span> <span style={{fontWeight: "700", fontSize: "1.2rem"}}>Conscious Proprioception&mdash;does this patient know where those feet are?</span> Knuckling, hopping, extensor postural thrust, and wheelbarrowing can all be used to address this question.  Certain disorders that affect the spinal cord will affect proprioception.
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Righting response</span>
        <img src={righting} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        Flip all four paws.  Deficits should be repeatable. When recording: 2/2 is normal, 1/2 is reduced (slow), 0/2 is absent.
        </div>
        </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Hopping</span>
        <img src={hopping} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        The patient should hop nicely.  The patient should not drag the leg. Use minimal or no support under the chest.  Any deficits should be repeatable.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Extensor Postural Thrust</span>
        <img src={extensor} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        The patient should walk backwards.  The patient should not drag the legs.  Any deficits should be repeatable.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>4.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Spinal Reflexes:</span> Spinal reflexes happen without conscious input from the brain.  There are many that can be tested, but the withdraw reflexes and patellar reflex are the most reliable.  Normal reflexes are scored 2/2.  Weak or absent reflexes are scored 1/2 or 0/2 respectively.  With certain disorders, the reflexes are more pronounced; these can be scored 3/4 or even 4/4 if clonus is observed.  This is because certain nerves are actually inhibitory and tone down the response to the stimulus.
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Withdraw reflex</span>
        <img src={withdraw} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        Pinch between the toes.  Even animals that have no conscious motor function and no pain perception may have a withdraw reflex.
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <span style={{fontSize: "1.2rem", fontWeight: "700", textAlign: "center", alignItems: "center"}}>Patellar reflex</span>
        <img src={patellar} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        <div className={classes.left}>
        Palpate the patellar tendon just below the kneecap.  The larger side of a reflex hammer should be used to perform this test (pay no attention to the tool used in the video).
        </div>
        </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>5.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}>Does the patient have any increased sensitivity or react painfully to touch?</span> This is termed hyperesthesia.  Animals with spinal cord injuries may have paraspinal hyperesthesia.  Animals with intracranial disease (headaches, brain tumors, inflammation) may also demonstrate hyperesthesia.
        </Grid>
        <Grid item xs={12} md={4}>
          <span style={{fontWeight: "700", fontSize: "1.6rem"}}>6.</span> <span style={{fontSize: "1.2rem", fontWeight: "700"}}> Superficial and Deep Pain:</span> Patients that do not have motor function should be evaluated for superficial and deep pain perception.  This is generally performed with a hemostat applied to webbing of the toes (superficial pain) or used to squeeze the toe bone (deep pain).  With Intervertebral disc disease there is a progression from pain to loss of proprioception to loss of motor function to loss of superficial pain perception to loss of deep pain perception.  This has some prognostic value as well when considering surgical decompression of the spinal cord.
        </Grid>
      </Grid>
      </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
