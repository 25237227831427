import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {SketchField, Tools} from 'react-sketch';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';


import examImage from '../images/examImage.jpg';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  divided: {
  margin: '20px 0px',
},
firstInput: {
  marginRight: '15px',
},
secondInput: {
  marginLeft: '15px',
}
});

const ranges = [
{
  value: '+',
  label: '+',
},
{
  value: '—',
  label: '—',
},
];

class EyeExamForm extends Component {

  state = {
    amount: '',
    password: '',
    weight: '',
    MenaceOD: '+',
    MenaceOS: '+',
    DazzleOD: '+',
    DazzleOS: '+',
    DPLROD: '+',
    DPLROS: '+',
    CPLROD: '+',
    CPLROS: '+',
    palpOD: '+',
    palpOS: '+',
    oculocephalicOD: '+',
    oculocephalicOS: '+',
    multiline: 'Controlled',
    showPassword: false,
    imageLoaded: false,
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleImageLoaded() {
    this.setState({ imageLoaded: true });
  }

  componentWillMount() {
    document.title = "Eye Exam";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.nintysix}>

        <h1>Ophthalmic Examination</h1>
        <Grid container justify="flex-start" className={classes.root}>

          <Grid item className={classes.left} xs={12}>
          <TextField
          id="date"
          type="date"
          defaultValue=""
          className={classes.textField}
          margin="normal"
          />
          </Grid>
          <Grid item className={classes.left} xs={12} sm={6}>
          <TextField
                   id="standard-textarea"
                   label="Number"
                   multiline
                   className={classes.textField}
                   margin="normal"
                 />
          </Grid>
          <Grid item className={classes.left} xs={12} sm={6}>


           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    className={classes.textField}
                    margin="normal"
                  />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="History"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Current Medications"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              label="Weight"
              id="simple-start-adornment"
              className={classes.textField}
              InputProps={{
                endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item xs={10} sm={5} md={4}>
            <Grid container justify='flex-start'>
              <Grid item xs={4} sm={6} md={3}>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2>OD</h2>
              </Grid>
              <Grid item xs={3} sm={3}>
                <h2>OS</h2>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>
              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Menace
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.MenaceOD}
                onChange={this.handleChange('MenaceOD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.MenaceOS}
                onChange={this.handleChange('MenaceOS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Dazzle
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.DazzleOD}
                onChange={this.handleChange('DazzleOD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.DazzleOS}
                onChange={this.handleChange('DazzleOS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Direct PLR
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.DPLROD}
                onChange={this.handleChange('DPLROD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.DPLROS}
                onChange={this.handleChange('DPLROS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Consensual PLR
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CPLROD}
                onChange={this.handleChange('CPLROD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.CPLROS}
                onChange={this.handleChange('CPLROS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Palpebral Reflex
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.palpOD}
                onChange={this.handleChange('palpOD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.palpOS}
                onChange={this.handleChange('palpOS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={12}>
                <Divider className={classes.divided}/>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Schirmer Tear Test
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
                endAdornment={<InputAdornment position="end">mm</InputAdornment>}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
                endAdornment={<InputAdornment position="end">mm</InputAdornment>}
              />
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Fluorescein Stain
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Tonometry
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Oculocephalic Reflex
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.oculocephalicOD}
                onChange={this.handleChange('oculocephalicOD')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={3}>
              <TextField
                select
                className={classes.textField}
                value={this.state.oculocephalicOS}
                onChange={this.handleChange('oculocephalicOS')}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

              <Grid item xs={4} sm={6} md={3}>
              <Typography>
              Retropulsion
              </Typography>
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.firstInput}
              />
              </Grid>
              <Grid item xs={3}>
              <Input className={classes.secondInput}
              />
              </Grid>
              <Grid item xs={0} md={3}>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={7} md={8}>
            <div className={classes.examImage}>
              <div style={{position: 'relative', width: '100%', height: 'auto', maxWidth: '650px'}}>
                <img style={{position: 'relative', width: '100%', height: 'auto', zIndex:'0'}} src={examImage} onLoad={this.handleImageLoaded.bind(this)} />
                {this.state && this.state.imageLoaded &&
                  <SketchField style= {{position: 'absolute', zIndex:'10', display: 'block', Height: '100%', width: '100%', top: '0', left: '0', overflow: 'hidden'}}
                               height="100%"
                               tool={Tools.Pencil}
                               lineColor='black'
                               lineWidth={1}/>
                }
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divided}/>
          </Grid>

          <Grid item className={classes.left} xs={12}>
            <TextField
              id="standard-full-width"
              label="Diagnosis/Recommendations"
              fullWidth
              multiline
              margin="normal"
              className={classes.textField}
            />
          </Grid>

        </Grid>
        </div>
      </div>

      );
  }
}

EyeExamForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EyeExamForm);
