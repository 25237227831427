import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Tooth from '../images/ToothAnatomy.jpg'
import Gingiva from '../images/Gingiva.jpg'

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>
    <Grid container spacing={16}>
      <Grid item xs={12} sm={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <img src={Tooth} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
      <Typography className={classes.contentBlock}>
      <List style={{lineHeight: '0px'}}>
      <ListItem>
      1. Root</ListItem><ListItem> 2. Apex and apical delta</ListItem><ListItem> 3. Root canal</ListItem><ListItem> 4. Periodontal ligament</ListItem><ListItem> 5. Alveolar crest</ListItem><ListItem> 6. Crown</ListItem><ListItem> 7. Enamel</ListItem><ListItem> 8. Pulp horn</ListItem><ListItem> 9. Dentin</ListItem><ListItem>10. Pulp chamber
      </ListItem>
      </List>

      </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper style={{textAlign: "center", margin: "auto"}}>
        <img src={Gingiva} style={{borderRadius:"8%", maxWidth: "96%"}}/>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
      <Typography className={classes.contentBlock}>
      <List style={{lineHeight: '0px'}}>
      <ListItem>
      1. Enamel </ListItem><ListItem>2. Dentin </ListItem><ListItem>3. Pulp </ListItem><ListItem>4. Cervical bulge </ListItem><ListItem>5. Gingival sulcus </ListItem><ListItem>6. Free gingiva </ListItem><ListItem>7. Attached gingiva </ListItem><ListItem>8. Mucogingival junction </ListItem><ListItem>9. Mucosa </ListItem><ListItem>10. Alveolar bone </ListItem><ListItem>11. Cemento-enamel junction </ListItem><ListItem>12. Junctional epithelium </ListItem><ListItem>13. Connective tissue attachment
      </ListItem><ListItem>14. Cementum</ListItem><ListItem>15. Periodontal ligament
      </ListItem>
      </List>
      </Typography>
      </Grid>
    </Grid>
    </div>


  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
