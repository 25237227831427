import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Seizures from '../../neuro/subtopics/Seizures';
import Intervertebral from '../../neuro/subtopics/IntervertebralDiscDisease';
import NeckPain from '../../neuro/subtopics/NeckPain';
import Vestibular from '../../neuro/subtopics/VestibularDisease';
import HeadTrauma from '../../neuro/subtopics/HeadTrauma';
import BrokenTooth from '../../dentistry/subtopics/BrokenTooth';
import ToothRootAbscess from '../../dentistry/subtopics/ToothRootAbscess';
import CornealUlcer from '../../eyes/subtopics/CornealUlcer';
import DryEye from '../../eyes/subtopics/DryEye';
import FelineHerpes from '../../eyes/subtopics/FelineHerpes';
import Glaucoma from '../../eyes/subtopics/Glaucoma';
import Proptosis from '../../eyes/subtopics/Proptosis';
import CHF from '../../heart/subtopics/CongestiveHeartFailure';
import Forelimb from '../../musclesandbones/subtopics/Forelimb';
import Hindlimb from '../../musclesandbones/subtopics/Hindlimb';
import AuralHematoma from '../../dermatology/subtopics/AuralHematoma';
import EarInfection from '../../dermatology/subtopics/EarInfection';
import PyotraumaticDermatitis from '../../dermatology/subtopics/PyotraumaticDermatitis';
import BleedingSkinMasses from './BleedingSkinMasses';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Brain & Spinal Cord</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Head Trauma</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <HeadTrauma/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Intervertebral Disc Disease</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Intervertebral/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Neck Pain</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <NeckPain/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Seizures</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Seizures/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Vestibular Disease</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Vestibular/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Dentistry</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Broken Tooth</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <BrokenTooth/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Tooth Root Abscess</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <ToothRootAbscess/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Eyes</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.subheading}>Corneal Ulcer</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
      <CornealUlcer/>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.subheading}>Dry Eye (keratoconjunctivitis sicca)</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
      <DryEye/>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.subheading}>Feline Herpes Keratoconjunctivitis</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
      <FelineHerpes/>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.subheading}>Glaucoma</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
      <Glaucoma/>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.subheading}>Proptosis of the Globe (eye popped out)</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.contentBlock}>
      <Proptosis/>
    </ExpansionPanelDetails>
    </ExpansionPanel>
    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Heart</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Congestive Heart Failure</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <CHF/>
      </ExpansionPanelDetails>
      </ExpansionPanel>

    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Muscles & Bones</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Forelimb</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Forelimb/>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Hindlimb</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <Hindlimb/>
      </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Back and Neck</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.contentBlock}>
        <div>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.subheading}>Intervertebral Disc Disease (IVDD)</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <Intervertebral/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.subheading}>Neck Pain</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.contentBlock}>
              <NeckPain/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Skin</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.fullwidth}>
    <div>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Aural Hematoma (Puffy/Swollen Ear Pinna)</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <AuralHematoma/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Ear Infection</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <EarInfection/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Pyotraumatic Dermatitis (Hot Spot)</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <PyotraumaticDermatitis/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Bleeding Skin Masses</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.contentBlock}>
        <BleedingSkinMasses/>
      </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
    </ExpansionPanelDetails>
    </ExpansionPanel>

    </div>



  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
