import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>ASA Physical Status Classification</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.fullwidth}>
        <Paper className={classes.tablePaper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">ASA PS</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">I</TableCell>
              <TableCell align="left">normal healthy patient</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">II</TableCell>
              <TableCell align="left">mild systemic disease</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">III</TableCell>
              <TableCell align="left">severe systemic disease</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">IV</TableCell>
              <TableCell align="left">severe systemic disease that is a constant threat to life</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">V</TableCell>
              <TableCell align="left">moribund, will not survive without operation</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">E</TableCell>
              <TableCell align="left">added to ASA PS in emergency surgeries when delaying treatment would add significant increase in threat to life or body part</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
        </ExpansionPanelDetails>
      </ExpansionPanel>



  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
