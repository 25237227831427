import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div className={classes.fullwidth}>

    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.subheading}>Dogs</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
      <div>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Paper className={classes.tablePaper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Approximate Age</TableCell>
                  <TableCell align="left">Teeth</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">2 - 4 weeks</TableCell>
                  <TableCell align="left">No noticeable tooth growth</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">3 - 4 weeks</TableCell>
                  <TableCell align="left">Deciduous canines coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">4 - 6 weeks</TableCell>
                  <TableCell align="left">Deciduous incisors and PMs coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">8 weeks</TableCell>
                  <TableCell align="left">All deciduous teeth present</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">3.5 - 4 months</TableCell>
                  <TableCell align="left">No noticeable permanent tooth growth</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">4 - 5 months</TableCell>
                  <TableCell align="left">Permanent incisors are coming in; some growth of PMs and Ms</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">5-7 months</TableCell>
                  <TableCell align="left">Permanent canines, PMs and Ms are coming in; all permanent teeth erupted by 7 months</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Typography style={{fontSize: ".8rem"}}>Table adapted from the 1996 Animal Sheltering "How To" Series from The Humane Society of the United States</Typography>
      </div>
      </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.subheading}>Cats</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <div>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Paper className={classes.tablePaper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Approximate Age</TableCell>
                  <TableCell align="left">Teeth</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">2 - 4 weeks</TableCell>
                  <TableCell align="left">Deciduous incisors coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">3 - 4 weeks</TableCell>
                  <TableCell align="left">Deciduous canines coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">4 - 6 weeks</TableCell>
                  <TableCell align="left">Deciduous PMs coming in on lower jaw</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">8 weeks</TableCell>
                  <TableCell align="left">All deciduous teeth present</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">3.5 - 4 months</TableCell>
                  <TableCell align="left">Permanent incisors coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">4 - 5 months</TableCell>
                  <TableCell align="left">Permanent canines, PMs and Ms are coming in</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">5-7 months</TableCell>
                  <TableCell align="left">All permanent teeth erupted by 6 months</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Typography style={{fontSize: ".8rem"}}>Table adapted from the 1996 Animal Sheltering "How To" Series from The Humane Society of the United States</Typography>
      </div>
      </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>

  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
