import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AdditionalResources from './subtopics/AdditionalResources'
import Heartworm from './subtopics/Heartworm'
import Fecal from './subtopics/Fecal';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },
  contentLink: {
    textDecoration: 'none',
    color: '#268bd2',
  }
});

class DogFieldMedicine extends Component {
  componentWillMount() {
    document.title = "Blood, Urine, & Fecal Testing";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
          <h1>Blood, Urine, & Fecal Testing</h1>


            <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>Heartworm Testing</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Heartworm/>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>

          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Fecal Testing</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Fecal />
          </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Urinalysis (UA)</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography className={classes.contentBlock}>
        <p>A urinalysis (UA) evaluates may aspects of the urine and can help detect urinary tract infection (UTI), kidney problems, diabetes and other endocrine diseases.</p>
        <p>Urine can be collected by free catch or cystocentesis (cysto).  Cystocentesis is a procedure in which a needle is placed through the abdomen into the bladder.</p>
        <p>Cystocentesis should be performed if the urine might be cultured for detection of UTI.  Free catch urine is often contaminated with bacteria and cells as it exits.</p>
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Complete Blood Count (CBC)</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography className={classes.contentBlock}>
        A complete blood count (CBC, hemogram) looks at the red blood cells, white blood cells, and platelets in the blood.  This test is used in combination with other tests and may help identify certain infections, cancers, autoimmune diseases, dehydration, and endocrine diseases.
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Chemistry</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography className={classes.contentBlock}>
      Serum chemistry panels may include many markers of internal disease including kidney values, liver values, electrolytes, and certain proteins.  Different chem panels are used in different situations depending on the patient and disease.  These tests are often interpreted with the CBC and Urinalysis.
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Packed Cell Volume/Total Solids (PCV/TS)</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography className={classes.contentBlock}>
        <div>
        <Paper className={classes.tablePaper}>

        <Table className={classes.table}>
        <TableBody>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Low PCV</TableCell>
              <TableCell align="center">Normal PCV</TableCell>
              <TableCell align="center">High PCV</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Low TPP</TableCell>
              <TableCell align="center">Blood loss, overhydration</TableCell>
              <TableCell align="center">PLN, PLE, Liver disease</TableCell>
              <TableCell align="center">Protein loss with splenic contraction, Dehydration masking more severe hypoproteinemia</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">Normal TPP</TableCell>
              <TableCell align="center">&uarr;RBC destruction, &darr;RBC production, Blood loss (minor, acute), Anemia of Chronic Disease</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Splenic contraction, Primary or Secondary Erythrocytosis, Dehydration masked hypoproteinemia</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">High TPP</TableCell>
              <TableCell align="center">Anemia of Chronic Disease, inflammation, lymphoid neoplasia, Dehydration masking more severe anemia</TableCell>
              <TableCell align="center">&uarr; globulin synthesis, Dehydration masked anemia</TableCell>
              <TableCell align="center">Dehydration</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </Paper>
        </div>
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Blood Smear</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
    <Grid container spacing={16}>
      <Grid item xs={12}>
      <Typography className={classes.contentBlock}>
      <a href="https://www.vet.cornell.edu/animal-health-diagnostic-center/laboratories/clinical-pathology/samples-and-submissions/hematology" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>How to make a blood smear</h3></a>
      <span>Cornell Vet Med Blood Smear Instructions</span>
      </Typography>
      <Typography className={classes.contentBlock} style={{paddingBottom: "2rem"}}>
      Always evaluate the monolayer.
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography className={classes.contentBlock} gutterBottom>
      <span style={{fontWeight: "700"}}>1. Estimate WBCs:</span> average # WBCs/10x field x 100-150 or average # WBCs/20x field x 400-600
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography className={classes.contentBlock} gutterBottom>
      <span style={{fontWeight: "700"}}>2. Calculate Absolutes:</span> Identify 100 consecutive WBCs and multiply by %
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography className={classes.contentBlock} gutterBottom>
      <span style={{fontWeight: "700"}}>3. Keep track of reticulocytes.</span> Reticulocytes are immature red blood cells.  They may be larger or stain more purple than mature RBCs.  Increased number of reticulocytes may indicate that the bone marrow is making more red blood cells or releasing them early.
      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography className={classes.contentBlock} gutterBottom>
      <span style={{fontWeight: "700"}}>4. Evaluate WBC and RBC morphology </span><a href="http://eclinpath.com/atlas/hematology/rbc-morphology/" target="_blank" rel="noopener noreferrer" className={classes.contentLink}><h3>eClinPath RBC Morphology Images</h3></a>

      </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
      <Typography className={classes.contentBlock}>
      <span style={{fontWeight: "700"}}>5. Platelet estimate</span>: average # platelets/100x field x 15-20,000; look at 10 fields; beware of platelet clumps at the feathered edge&mdash;these will decrease your count.
      </Typography>
      </Grid>
    </Grid>
    </ExpansionPanelDetails>
  </ExpansionPanel>

  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography className={classes.heading}>Blood Clotting Tests</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography className={classes.contentBlock}>
      <p>Coaggulation (the process of blood clotting) is important, but complicated.  Several different tests may be needed to understand blood clotting problems in dogs and cats.</p>
      <p><span style={{fontWeight: "700"}}>PT/PTT:</span> The combination of these tests can help identify problems with coagulation (clotting) factors.</p>
      <p><span style={{fontWeight: "700"}}>Platelet number:</span> Hematology analyzers or manual counts from blood smears can help identify when platelet number is low.  Cat platelets commonly clump and make our counts inaccurate.  To estimate platelets from a blood smear, count the platelets in 10 microscopic fields at 100x, average this number, and multiply by 15,000.</p>
      <p><span style={{fontWeight: "700"}}>BMBT:</span> Buccal Mucosal Bleeding Time (BMBT) can help detect problems with platelet function.</p>
      <p><span style={{fontWeight: "700"}}>vWF:</span> This antigen test is sent to a reference lab.  von Willebrand Factor (vWF) plays a role in platelet adhesion.  Doberman Pinschers, German shorthaired pointers, German wirehaired pointers, Scottish terriers, and Shetland sheepdogs are at greatest risk of von Willebrand Disease (vWD).</p>
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>

      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Additional Resources</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <AdditionalResources/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <div style={{paddingTop: "30px"}}>
      <Link to="/vet-nurse-guide" style={{fontSize: "1.3rem", color: "maroon", textDecoration: "none", border: '1px solid maroon', borderRadius: '5px', padding: '2px 10px',}}>All Topics</Link>
      </div>

    </div>

  </div>
  </div>

      );
  }
}

DogFieldMedicine.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DogFieldMedicine);
