import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    paddingTop: '30px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  tablePaper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
});

function SimpleExpansionPanel(props) {
  const { classes } = props;
  return (

    <div className={classes.contentBlock}>


      <Typography>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Chevron sign</span> &mdash; Normal lucency at the apex of a root on dental radiographs - should not have any mushroom cloud effect
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Complicated Crown Fracture</span> &mdash; fracture of the crown that involves the root
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Dentigerous cyst</span> &mdash; forms because a tooth and its inner enamel epithelium are left unerrupted - an osmotic gradient forms and pulls fluid into a sac creating a destructive cyst
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Dilacerated root</span> &mdash; these roots are more parallel or convergent
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Epulis</span> &mdash; proliferative growth of the gingiva; nonspecific; benign or malignant; odontogenic fibromas include the fibromatous epulis and ossifying epulis - firm gingival masses from gingival connective tissue or the periodontal ligament. Acanthomatous ameloblastomas are benign but locally invasive and usually affect the rostral mandible.
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Fused roots</span> &mdash; when roots are fused; an incidental finding
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Gemination tooth</span> &mdash; twinning of the tooth; an incidental finding
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Supernumerary root</span> &mdash; an extra root on a tooth - gives a good reason to take radiographs before extraction
        </Grid>
        <Grid item xs={12} sm={4}>
          <span style={{fontWeight: "700"}}>Uncomplicated Crown Fracture</span> &mdash; fracture of the crown that does not involve the root
        </Grid>
      </Grid>
      </Typography>
    </div>

  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
