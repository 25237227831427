import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },

  topicsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    maxWidth: '1400px',
    justifyContent: 'center',
  },
});

class CongestiveHeartFailureQS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weightlbs: '',
      weightkg: '',
      checked: false,
    }
    this.handlelbsChange = this.handlelbsChange.bind(this);
    this.handlekgChange = this.handlekgChange.bind(this);
    this.setChecked = this.setChecked.bind(this);

  }
  setChecked(event) {
    this.setState({
      checked: event.target.checked
    })
  }

  handlelbsChange(event) {
    this.setState({
      weightlbs: event.target.value,
      weightkg: Number(parseFloat(event.target.value)/2.2).toFixed(1)
    });
  }
  handlekgChange(event) {
    this.setState({
      weightkg: event.target.value,
      weightlbs: Number(parseFloat(event.target.value)*2.2).toFixed(1)
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
      <div className={classes.twelvehundred}>
        <div className={classes.nintysix}>
        <h1 className="calculator-h1">Congestive Heart Failure</h1>
        <h2 className="calculator-h1">Clinical Quick Sheet</h2>

        <Grid container spacing={0} style={{textAlign: "left"}}>
          <Grid item xs={12}>
          <TextField
          id="date"
          type="date"
          defaultValue=""
          margin="normal"
          size="small"
          />
          </Grid>

          <Grid item xs={12}>
           <TextField
                    id="standard-textarea"
                    label="Name"
                    multiline
                    margin="normal"
                    size="small"
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Grid container>
          <Grid item xs={12}>
           <TextField
                    id="weightlbs"
                    type="number" value={this.state.weightlbs} onChange={this.handlelbsChange}
                    label="Weight (lbs)"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment>lbs</InputAdornment>,
                    }}
                  />
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                           id="weightkg"
                           type="number" value={this.state.weightkg} onChange={this.handlekgChange}
                           label="Weight (kg)"
                           margin="normal"
                           variant="outlined"
                           InputProps={{
                             endAdornment: <InputAdornment>kg</InputAdornment>,
                           }}
                         />
                  </Grid>

                </Grid>

          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            <span style={{fontWeight: '700'}}>Presentation:</span> Respiratory distress with heart murmur, and signs of decreased perfusion (purple mucous membranes, tired, weak), may hear crackles on auscultation of lung fields, history of cough
            </li>
            <li>
            <span style={{fontWeight: '700'}}>Ddx:</span> Tracheal collapse, Pneumonia, Feline Asthma, Pulmonary Hypertension, Anemia (with subsequent hypoxia), Trauma, Neoplasia, Other
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            /><span style={{fontWeight: '700'}}>Decrease patient stress and provide oxygen.</span>  Give ____ mL Butorphanol (10 mg/mL) IM
          </Grid>

          <Grid container align="left">
            <Grid item xs={12}>
            <ul>
            <li>
            Consider Butorphanol at 0.2 to 0.4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)/10).toFixed(2)} mL to {(this.state.weightkg*(.4)/10).toFixed(2)} mL</span> of 10 mg/mL Butorphanol for this patient.
            </li>
            <li>
            Place directly in oxygen cage if possible. DO NOT attempt immediate catheter placement, blood draw, or radiographs in severely compromised patients.  Can give furosemide at presentation prior to diagnostics.
            </li>
            </ul>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />Chemistry panel
          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
            To assess baseline renal values and electrolytes
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />CBC
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />3 view thoracic radiographs
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
Consider DV instead of VD to reduce patient stress
          </li>
          </ul>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            />IV catheter (if not already placed)

          </Grid>
          <Grid item xs={12} style={{textAlign: 'left'}}>

          <ul>
          <li>
It is not indicated (and counterproductive) to give IV (or SC) fluids while on furosemide for treatment of pulmonary edema.
          </li>
          </ul>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{margin: '20px 0px'}}/>
          </Grid>

          <Grid item xs={12} style={{textAlign: 'left'}}>
            <Checkbox
            checked={this.state.checked}
            onChange={this.setChecked}
            color="primary"
            label="Emergency drug sheet printed"
            inputProps={{ 'aria-label': 'emergency drugs printed' }}
            /><span style={{fontWeight: '700'}}>Decrease pulmonary edema.</span> Give ____ mL Furosemide (50 mg/mL) IM or IV.
          </Grid>

            <Grid item xs={12}>
            <ul>
            <li>
            Furosemide at 1-4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(1)/50).toFixed(2)} mL to {(this.state.weightkg*(4)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide for this patient.
            </li>
            <li>
            If moderate, 2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide for this patient.
            </li>
            <li>
            If severe, 4 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(4)/50).toFixed(2)} mL</span> of 50 mg/mL Furosemide for this patient.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              checked={this.state.checked}
              onChange={this.setChecked}
              color="primary"
              label="Emergency drug sheet printed"
              inputProps={{ 'aria-label': 'emergency drugs printed' }}
              /><span style={{fontWeight: '700'}}>Monitor respiratory rate.</span>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
Normal is &le;30.  Once stable, can remove from oxygen and continue to monitor.
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <Checkbox
              checked={this.state.checked}
              onChange={this.setChecked}
              color="primary"
              label="Emergency drug sheet printed"
              inputProps={{ 'aria-label': 'emergency drugs printed' }}
              /><span style={{fontWeight: '700'}}>Start Pimobendan</span> once eating/able to tolerate oral medications.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            0.2 to 0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)).toFixed(2)} mg to {(this.state.weightkg*(.3)).toFixed(2)} mg</span> q12h
            </li>
            <li>
            Total daily dose of 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.5)).toFixed(2)} mg</span> can be split into different morning/evening dose.
            </li>
            <li>
            Vetmedin chewable tablets are available as: 1.25 mg, 2.5 mg, 5 mg, and 10 mg
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Medications for long-term management:
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Furosemide (Salix)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give INPUT tablet(s) by mouth every INPUT hours until otherwise directed.  This is a diuretic heart medication that may cause increased drinking and urination. Do not restrict water. Next dose due INPUT.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)).toFixed(2)} mg</span> q8-12h
            </li>
            <li>
            Furosemide (Salix) tablets are available as: 12.5 mg, 50 mg; generic 20 mg, 40 mg, 80 mg or 10 mg/mL solution
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Pimobendan (Vetmedin)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give INPUT tablet(s) by mouth every 12 hours until otherwise directed.  This is a heart medication and may be required for the rest of your pet's life. Next dose due INPUT.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            0.2 to 0.3 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.2)).toFixed(2)} mg to {(this.state.weightkg*(.3)).toFixed(2)} mg</span>
            </li>
            <li>
            Total daily dose of 0.5 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(.5)).toFixed(2)} mg</span> can be split into different morning/evening dose.
            </li>
            <li>
            Vetmedin chewable tablets are available as: 1.25 mg, 2.5 mg, 5 mg, and 10 mg
            </li>
            </ul>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              <span style={{textDecoration: 'underline'}}>Spironolactone (Aldactone)</span>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>
              Give INPUT tablet(s) by mouth every 24 hours until otherwise directed.  This is a heart medication. Next dose due INPUT.
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left'}}>

            <ul>
            <li>
            2 mg/kg = <span style={{backgroundColor: '#fff2ac'}}>{(this.state.weightkg*(2)).toFixed(2)} mg</span> q8-12h
            </li>
            <li>
            Available as 25 mg, 50 mg, 100 mg tablets
            </li>
            </ul>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>

            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Intake video:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <h3><a href="https://youtu.be/NITlKMxJqE4" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>YouTube: Heart Failure Intake Video</a></h3>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Informational Handouts:
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <h3><a href="https://veterinarypartner.vin.com/default.aspx?pid=19239&id=8501760" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Congestive Heart Failure</a> <span style={{fontWeight: "500"}}>from Veterinary Partner</span></h3>
            </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography>
            <h3><a href="http://cardiaceducationgroup.org/wp-content/uploads/2015/09/CEG_resp-rate_handout_FINAL.pdf" target="_blank" rel="noopener noreferrer" className={classes.contentLink}>Resting Respiratory Rate</a> <span style={{fontWeight: "500"}}>from Cardiac Education Group</span></h3>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{margin: '20px 0px'}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'left', fontWeight: '700'}}>
            Discharge Note (Key Points):
            </Grid>
            <Grid item xs={12}>
            <ul>
            <li>
            Monitor your pet's breathing rate at home.  When your pet is resting or sleeping, the breathing rate should be &le;30 breaths per minute.  You will soon learn your pet's baseline breathing rate.  If this rate becomes consistently higher or you notice increase coughing or nostril flaring, prompt veterinary attention is necessary as this may indicate fluid in the lungs associated with congestive heart failure.  A journal or the Cardalis app may be helpful.
            </li>
            <li>
            Decrease activity at home.  Keep your pet calm and resting for the next few weeks.
            </li>
            <li>
            Do NOT offer salty treats.  An increase in dietary sodium can put your pet back in heart failure and back in the hospital.
            </li>
            <li>
            Your pet should always have free access to fresh water while on the diuretic medication (furosemide).  Do NOT restrict access to water.
            </li>
            <li>
            Schedule a follow up appointment for 7-10 days (recheck sooner if any concerns arise).  At this visit, we may recommend rechecking lab work, rechecking x-ray imaging of the chest, and adjusting medications.
            </li>
            </ul>
            </Grid>



        </Grid>



        </div>
        </div>
      </div>
    )
  }


};

export default withStyles(styles)(CongestiveHeartFailureQS);
