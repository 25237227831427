import React from 'react';
import { Link } from "react-router-dom";

const classes =  {
  topicButtonSpacing: {
    margin: '5px 10px'
  },

  topicButton: {
    color: '#510505',
    padding: '0 0 10px 0',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inlineBlock',
    boxShadow: '0px 2px 1px rgba(0,0,0,.3)',
    marginBottom: '.5rem',
    border: '1px solid rgba(0,0,0,.1)',
    borderRadius: '4px',
    transition: 'all .25s linear',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontFamily: "'Spectral SC', serif",
  },

  topicButtonText: {
    color: '#510505',
    padding: '0 0 10px 0',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all .25s linear',
    cursor: 'pointer',
    fontSize: '1.4rem',
    fontFamily: "'Spectral SC', serif",
  },

  topicPic: {
    width: '300px',
    height: '150px',
    overflow: 'hidden',
    borderRadius: '4px 4px 0 0',
    display: 'flex',
    alignItems: 'center',
  },

  pictureWidth: {
    width: '100%'
  }

};



function TopicButton (props) {
  return (
    <div style={classes.topicButtonSpacing}>
    <div style={classes.topicButton}>
      <Link style={classes.topicButtonText} to={props.route}>
        <div style={classes.topicPic}>
          <img src={props.pic} style={classes.pictureWidth} alt={props.topic + " image"}/>
        </div>
        {props.topic}
      </Link>
    </div>
    </div>

  )
}


export default TopicButton;
