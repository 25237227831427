import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import derm from './images/derm.jpg';
import dentistry from './images/dentistry.jpg';
import emergency from './images/emergency.jpg';
import ophtho from './images/ophtho.jpg';
import anesthesia from './images/anesthesia.jpg';
import cardio from './images/cardio.jpg';
import clinPath from './images/clin-path.jpg';
import musculoskeletal from './images/musculoskeletal.jpg'
import neuro from './images/neuro.jpg';
import nutrition from './images/nutrition.jpg';
import onco from './images/onco.jpg'
import preventative from './images/preventative.jpg';




import TopicButton from './TopicButton';

const styles = theme => ({
  root: {
    fontFamily: 'Spectral',
    width: '100%',
    margin: 'auto',
    paddingTop: '30px',
    textAlign: 'center',
    backgroundColor: 'white',
    paddingBottom: '20px',
  },
  twelvehundred: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  nintysix: {
    maxWidth: "96%",
    margin: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  fullwidth: {
    fontFamily: 'Spectral',
    flexBasis: '100%',
    width: '100%',
    margin: '0',
    padding: '0',
  },
  left: {
    fontFamily: 'Spectral',
    textAlign: 'left',
  },
  subheading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Spectral',
  },
  contentBlock: {
    fontSize: theme.typography.pxToRem(15),
    textAlign: 'left',
  },
  homeButton: {
    color: '#000',
    paddingTop: '40px',
  },
  bigAvatar: {
  margin: 10,
  width: 60,
  height: 60,
  },
  spectral: {
    fontFamily: 'Spectral',
  },
  justify: {
    textAlign: 'justify'
  },
  iconButton: {
    fontFamily: 'Material Icons',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '1.8rem',
    display: 'inline-block',
    lineHeight: '1',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'nowrap',
    direction: 'ltr',
    color: '#510505',
    border: '1px solid #510505',
    borderRadius: '50%',
    padding: '5px',
  },
  tempDiv: {
    width: '100%',
    margin: 'auto',
  },
  tempDog: {
    diplay: 'block',
    margin: 'auto',
    width: '20vw',
    maxWidth: '151px',
    maxHeight: '182px',
  },

  topicsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    maxWidth: '1400px',
    justifyContent: 'center',
  },
});


class VetNurseGuide extends Component {

  componentDidMount() {
    document.title = "Veterinary Nursing Guide";
  };
      render() {
          const { classes } = this.props;
          return (
              <div className={classes.root}>
              <div className={classes.twelvehundred}>
              <div className={classes.nintysix}>
                          <h1>Veterinary Nursing Guide</h1>

                <div className={classes.topicsContainer}>

                  <TopicButton route="/anesthesia" pic={anesthesia} topic="Anesthesia"/>
                  <TopicButton route="/bloodandurine" pic={clinPath} topic="Blood, Urine, & Fecal Testing"/>
                  <TopicButton route="/brainandspinalcord" pic={neuro} topic="Brain & Spinal Cord"/>
                  <TopicButton route="/cancer" pic={onco} topic="Cancer"/>
                  <TopicButton route="/dentistry" pic={dentistry} topic="Dentistry"/>
                  <TopicButton route="/emergency" pic={emergency} topic="Emergency"/>
                  <TopicButton route="/eyes" pic={ophtho} topic="Eyes"/>
                  <TopicButton route="/heart" pic={cardio} topic="Heart"/>
                  <TopicButton route="/muscles-and-bones" pic={musculoskeletal} topic="Muscles & Bones"/>
                  <TopicButton route="/nutrition" pic={nutrition} topic="Nutrition"/>
                  <TopicButton route="/skin" pic={derm} topic="Skin"/>
                  <TopicButton route="/vaccines-and-wellness" pic={preventative} topic="Vaccines & Wellness"/>

                </div>
              </div>
              </div>
              </div>

      );
  }
}

VetNurseGuide.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VetNurseGuide);
